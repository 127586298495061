import Footer from "../Home/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import MainNav from "../Home/Navbar/MainNav";
import Container from "react-bootstrap/Container";
import "./FAQsPage.css";
const FAQsPage =()=>{
    return(
<>
<Container fluid className="mb-5" style={{backgroundColor:"#072c5e"}}>
        <MainNav/>  
        {/*responsive navbar*/}
      </Container>
<div className="container-fluid d-flex justify-content-center mb-5 pb-5">
    <div className="col-md-8">
    <div className="row">
        <h1 className="mb-5" style={{color:"#1c287b",textAlign:"center"}}>Frequently Asked Questions</h1>
    </div>
    <div className="row justify-content-center">
    <div class="accordion" id="accordionPanelsStayOpenExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
      Q1: Why choose a Faceless Loans for my home Loan?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
      <p><b>Ans:</b></p>
        <ol>
          <li className="ml-45"><b>Convenience:</b> You can apply for a home loan from anywhere in the world with an internet connection.</li>
          <li className="ml-45"><b>Speed:</b> Faceless loans are processed much faster than traditional home loans. You can often get pre-approved for your home loan in minutes and close on your loan in a few weeks.</li>
          <li className="ml-45"><b>Choice:</b> Lenders offer a wide variety of loan products and terms to choose from. This means that you can find a loan that is perfect for your individual needs.</li>
          <li className="ml-45"><b>Competitive rates:</b> Faceless loans provides multiple options in lenders and ultimately try to reduce your ROI.</li>
        </ol>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
      Q2: How do I get started with my Faceless Loan Application?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div class="accordion-body">
        <p><b>Ans. </b> To get started, simply visit our website and fill out our online application. We will then review your application and contact you to discuss your needs in more detail.</p>
        <strong>We look forward to helping you achieve your homeownership goals!</strong> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
      Q3: What are the eligibility requirements for a Faceless Home Loan?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
      <div class="accordion-body">
      <p><b>Ans:</b> Your loan eligibility is determined by your:</p>
        <ol>
          <li className="ml-45">Income/ Revenue</li>
          <li className="ml-45">Repayment capacity</li>
          <li className="ml-45">Existing credit facilities</li>
          <li className="ml-45">Any other factors as set by lenders</li>
        </ol>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
      Q4: What documents will I need to provide for Faceless Home Loan?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
      <div class="accordion-body">
      <p><b>Ans:</b></p>
      <ol>
        <li className="ml-45">KYC Documents</li>
        <li className="ml-45">Salary Slip /Form 16/ Financials</li>
        <li className="ml-45">Bank Statements of 1 year</li>
        <li className="ml-45">Sanction Letters and Loan Account Statements</li>
        <li className="ml-45">Property Documents</li>
      </ol> 
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
      Q5: What are the fees associated with Faceless Home Loan?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
      <div class="accordion-body">
     <p><b>Ans: </b>We charge very minimal Application fees of Rs. 250.</p>
     
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
      Q6: How Long does it take to get approved Faceless Home Loan?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
      <div class="accordion-body">
        <p><b>Ans: </b>The time it takes to get approved for a faceless Home Loan will vary depending on the Bank. However, most Banks will be able to approve you for loan from 2 days to a week.</p>
        
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
      Q7: Will I receive a loan if I pay the application fees and receive a soft approval?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
      <div class="accordion-body">
      <p><b>Ans: </b>The borrower's data is used to determine the soft approval. after offering an in-principle approval, the preferred lender (as chosen by the borrower) would do extensive due diligence and decide whether to sanction/disburse/reject the application. The ultimate decision will be at the discretion of the lender.</p> 
        
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseEight">
      Q8: How long will it take for me to receive a final decision and funds?
      </button>
    </h2>
    <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingEight">
      <div class="accordion-body">
      <p><b>Ans: </b>A borrower can obtain soft approval in just two days from any location at any time. Following soft approval, the time it takes for loan disbursement is determined by the information and documentation you give on the portal and banks. The more accurate the data, the faster you get your disbursement. In general, after digital approval, the loan should be sanctioned/disbursed within 7-8 working days.</p> 
        
      </div>
    </div>
  </div>
</div>
</div>
    </div>
</div>

<Footer/>
</>
    );
} 

export default FAQsPage;