import "./UpdatePassword.css";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import Select from "react-select";
import { postUpdatePassword } from "../../Service/postUpdatePassword";
import swal from "sweetalert";

const SignupSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  newpassword: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  updateRole: Yup.string().required("Required"),  
});

export default class UpdatePassword extends Component {
  state = {
    roleList : [
      { value: "ADMIN", label: "Admin"},
      { value: "EMPLOYEE", label: "Employee"},
    ]
  };
  
  render() {
    return (
      <div className="contactus__wrapper">
        <Formik
          initialValues={{
            oldpassword: "",
            newpassword: "",
            updateRole: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            const data = {
              old_password: values.oldpassword,
              new_password: values.newpassword,
              role: values.updateRole,
            };
            console.log("Update Password Data ---->", data);
            await postUpdatePassword(data)
              .then((result) => {
                console.log("Result", result);
                swal("Great", "Password change successfully", "success");
                window.location.reload();
                return Promise.resolve();
              })
              .catch((result) => {
                swal(
                  "Failed",
                  "Something went wrong please check your internet",
                  "error"
                );
              });
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <div className="mt-7 container-fluid">
              <div className="row">
                <div className="order-xl-1 col-xl-12">
                  <div className="shadow card">
                    <div className="card-body">
                      <Form>
                        <h4 className="heading-small font-weight-bold mb-4">
                          Update Password
                        </h4>
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-lg-4 update_password">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Old Password
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="oldpassword"
                                  type="password"
                                  placeholder="Old Password"
                                />
                                {errors.oldpassword && touched.oldpassword ? (
                                  <div className="error__msg">
                                    {errors.oldpassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 update_password">
                              <div className="form-group">
                                <label className="form-control-label">
                                  New Password
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="newpassword"
                                  type="password"
                                  placeholder="New Password"
                                />
                                {errors.newpassword && touched.newpassword ? (
                                  <div className="error__msg">
                                    {errors.newpassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 update_password">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Role
                                </label>
                                <span className="mustRequired">*</span>
                                <Select
                                  options={this.state.roleList}
                                  onChange={(e) => {
                                    setFieldValue("updateRole", e.value);
                                  }}
                                />
                                {errors.updateRole && touched.updateRole ? (
                                  <div className="error__msg">
                                    {errors.updateRole}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-new"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}
