import React, { PureComponent } from "react";
import DashboardAdmin from "./DashboardAdmin";
import DashboardEmployee from "./DashoardEmployee";

class Dashboard extends PureComponent {
  state = {
    Role: "",
  };

  async componentDidMount() {
    let role = localStorage.getItem("role");
    console.log("role", role);
    this.setState({ Role: role });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  render() {
    return (
      <>
        {this.state.Role === "ADMIN" ? (
          <DashboardAdmin />
        ) : this.state.Role === "EMPLOYEE" ? (
          <DashboardEmployee />
        ) : ""}
      </>
    );
  }
}

export default Dashboard;
