import "./BankPartnerList.css";

import React, { Component } from "react";

import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { getBankPartnerList } from "../../Service/getBankPartnerList";

export default class BankPartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      Role: "",
      loader: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  async getData() {
    let result = await getBankPartnerList();
    console.log("payloadresult", result);
    if (
      result &&
      result.data &&
      result.data.payload &&
      result.data.payload.length !== 0
    ) {
      const tdata = result.data.payload;
      const slice = tdata.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice,
        loader: false,
      });
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loader: true });
      this.getData();
      this.setState({ loader: false });
    } catch (error) {
      console.log(error);
      this.setState({ loader: false });
    }
  }

  redirectToAddBankPartner = () => {
    this.props.history.push("/add_bank_partner");
  };

  render() {
    const { tableData, loader } = this.state;
    return (
      <>
        {loader ? (
          <div className="loader"></div>
        ) : (
          <div className="mt-7 container-fluid">
            <div className="row">
              <div className="col">
                <div className="shadow card">
                  <div className="border-0 card-header">
                    <div className="userList__btn__wrapper">
                      <button
                        className="btn btn-primary font-weight-bold"
                        onClick={this.redirectToAddBankPartner}
                      >
                        ADD BANK PARTNER
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="align-items-center table-flush table">
                      <thead className="thead-light">
                        <tr>
                          <th>Sr No</th>
                          <th>Bank Name</th>
                          <th>Contact Person Name</th>
                          <th>Contact Person Mobile</th>
                          <th>Contact Person Email</th>
                          <th>Bank Address</th>
                          <th>Edit Bank Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((obj, key) => (
                          <tr key={key}>
                            <td>{key + this.state.offset + 1}</td>
                            <td>{obj.bank_name}</td>
                            <td>{obj.contact_person_name}</td>
                            <td>{obj.contact_person_email}</td>
                            <td>{obj.contact_person_mobile}</td>
                            <td>{obj.bank_address}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/edit_bank_partner",
                                  state: {
                                    bank_id: obj.bank_id,
                                    bank_name: obj.bank_name,
                                    contact_person_name:
                                      obj.contact_person_name,
                                    contact_person_mobile:
                                      obj.contact_person_mobile,
                                    contact_person_email:
                                      obj.contact_person_email,
                                    bank_address: obj.bank_address,
                                  },
                                }}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4 card-footer">
                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-left"></i>}
                      nextLabel={<i className="fa fa-angle-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
