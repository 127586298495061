import Footer from "../Home/Footer/Footer";
import MainNav from "../Home/Navbar/MainNav";
import {
  Container,
  Row,
} from "react-bootstrap";

const AboutUs =()=>{
    return(
        <>
        <Container fluid className="mb-5" style={{backgroundColor:"#072c5e"}}>
        <MainNav/>
        {/*responsive navbar*/}
      </Container>
      <Container className="pb-5">
        <div className="row">
            <h1 style={{textAlign:"center",color:"#072c5e"}}>About Us</h1>
            <Row className="justify-content-center">
            <div className="col-md-8 mt-3">
              <p style={{fontSize:"16px"}}>Faceless Loans is a digital home loan platform that makes it easy and convenient to get a home loan. We understand that buying a home is one of the biggest financial decisions you will ever make, and we are here to help you every step of the way.</p>
              <p style={{fontSize:"16px"}}>We offer a variety of home loan products and services to meet the needs of all borrowers, regardless of your credit history or income. We work with a wide network of banks to get you the best possible rate and terms on your home loan.</p>
                <h4 style={{color:"#072c5e"}}>How it Works</h4>
                <p>
                To get started, simply fill out a short online application. We will then review your application and contact you to discuss your needs in more detail. Once we have a good understanding of your situation, we will work with our network of banks to find the best possible home loan for you.
                </p>
                <p>
                Once you have selected a home loan product, we will handle all of the paperwork and coordination with the lender. We will also work with you to ensure that you understand all of the terms and conditions of your loan before you sign anything.
                </p>
            </div>
            <div className="col-md-4">
            <img src="/assets/img/homepage/home-about.jpg" className="img-fluid" style={{}}  alt="" />
            </div>
            </Row>
            <Row className="">
            <div className="col-md-4">
            <img src="/assets/img/homepage/House.jpg" className="img-fluid" style={{}}  alt="" />
            </div> 
            <div className="col-md-8 mt-3">
                <h4 style={{color:"#072c5e"}}>Why Choose Us?</h4>
                <p>
                There are many reasons to choose <strong>Faceless Loans</strong> for your home loan needs. Here are just a few:
                </p>
                <ul>
                    <li><strong>Convenience: </strong>We offer a completely digital home loan process. You can apply for a loan, get pre-approved, and even close on your loan without ever having to set foot in a bank.</li>
                    <br/><li><strong>Expertise: </strong>We have a team of experienced home loan professionals who are here to help you every step of the way. We can answer all of your questions and help you navigate the home loan process.</li>
                    <br/><li><strong>Choice:</strong> We offer a variety of home loan products and services to meet the needs of all borrowers. We can help you get a loan to buy your first home, refinance your existing mortgage, or even invest in a rental property</li>
                    <br/><li><strong>Competitive Rates:</strong> We work with a wide network of banks to get you the best possible rate and terms on your home loan.</li>
                </ul>
            </div>

            <div className="col">
            <h4 style={{color:"#072c5e"}}>Mission:</h4>
            <p style={{fontSize:"16px"}}>To make home more accessible and affordable for everyone.</p>
            <h4 style={{color:"#072c5e"}}>Vision:</h4>
            <p style={{fontSize:"16px"}}>To be the leading digital home loan platform in the world, providing our customers with the best possible experience and the best possible rates.</p>
            <br/>
            <p style={{fontSize:"16px"}}>We believe that everyone deserves to have a place to call home. That's why we are committed to making homeownership more accessible and affordable for everyone. We do this by offering a completely digital home loan process, working with a wide network of banks to get our customers the best possible rates and terms, and providing them with expert guidance and support throughout the process.</p>
            <br/>
            <p style={{fontSize:"16px"}}>We are also committed to innovation. We are constantly looking for new ways to improve the home loan process and make it even easier and more convenient for our customers. We believe that digital lending is the future of the home loan industry, and we are excited to be at the forefront of this movement.</p>
            <br/>
            <p style={{fontSize:"16px"}}>We are proud of the work that we do, and we are grateful for the opportunity to help our customers achieve their homeownership goals. We look forward to continuing to grow and innovate in the years to come.</p>
            </div>
            </Row>
        </div>
      </Container>
      <Footer/>
        </>
    );
}

export default AboutUs;