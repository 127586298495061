import React, { useState, useEffect } from 'react';
import './TextAnimation.css';

const TextAnimation = ({ words, duration }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) =>
        (prevIndex + 1) % words.length
      );
    }, duration);

    return () => clearInterval(interval);
  }, [words, duration]);

  return (
    <span className="text-animation">
      {words.map((word, index) => (
        <span
          key={index}
          className={`animated-word ${
            index === currentWordIndex ? 'active' : ''
          }`}
        >
          {word}
        </span>
      ))}
    </span>
  );
};

export default TextAnimation;
