import React from "react";
import { Container, Row} from "react-bootstrap";
import "./BringingYou.css";
import "../style.css";
function BringingYou() {
  return (
    <>
      <Container fluid className="my-0 section-fifth">
        <Row className="br-p-50">
          <Row className=" mt-2">
            <div className="col-lg-9 col-sm-6 col-12 position-relative">
              <h2 className="mb-5 mob-bot-0 by-title">
              Bringing your Home Loan journey at your fingertips
              </h2>
            </div>
          </Row>
          <div className="box-container mt-2 by-pt-90 " >
            <div className="sm-card-mt-15 box" style={{boxShadow:"0px 0px 7px #c0c0c0",borderRadius:"5px",textAlign:"center"}}>
            <div className="bring-icon">
                    <img src="/assets/img/homepage/online-form.svg" style={{width:"60px"}}  alt="" />
                    </div>
                <h4 className="white-link1">Complete Online Process</h4>
                <p className="white-link">
                  Complete your application in minutes
                </p>
            </div>
              <div className="sm-card-mt-15 box" style={{boxShadow:"0px 0px 7px #c0c0c0",borderRadius:"5px",textAlign:"center"}}>
              <div className="bring-icon">
                    <img src="/assets/img/homepage/rupee.svg" style={{width:"60px"}}  alt="" />
                    </div>
                <h4 className="white-link1">Minimal Application Fees</h4>
                <p className="white-link">
                Charge very low fees, which are refundable.
                </p>
              </div>
              <div className="sm-card-mt-15 box" style={{boxShadow:"0px 0px 7px #c0c0c0",borderRadius:"5px",textAlign:"center"}}>
              <div className="bring-icon">
                    <img src="/assets/img/homepage/approve.svg" style={{width:"60px"}}  alt="" />
                    </div>
                <h4 className="white-link1">Approval within 2 Working Days</h4>
                <p className="white-link">              
                    Process completed within 2 working days. 
                </p>
              </div>
              <div className="sm-card-mt-15 box" style={{boxShadow:"0px 0px 7px #c0c0c0",borderRadius:"5px",textAlign:"center"}}>
              <div className="bring-icon">
                    <img src="/assets/img/homepage/tracking.svg" style={{width:"60px"}}  alt="" />
                    </div>
                <h4 className="white-link1"> Track your Application</h4>
                <p className="white-link">
                  Access all your loan documents easily on our platform
                </p>
              </div>
            
          </div>
        </Row>
      </Container>
    </>
  );
}

export default BringingYou;
