
import "./weOffer.css";
const WeOffer = ()=>{

    return(
        <>
        <div className="container-fluid weoffer">
            <div className="row p-50" style={{justifyContent:"space-around"}}>
                <h2 className="mb-5 mt-5 my-5"><u className="underLine">We offer loan for</u></h2>
                <div className="col-md-3 mbox">
                <div className="card" style={{width:"18rem"}}>
                    <img src="/assets/img/homepage/underConstructionApartment.jpg" className="card-img-top" alt="underConstructionApartment"/>
                    <div className="card-body" style={{paddingBottom:"40px"}}>
                        <p className="card-text subtitle"> Under-construction Apartments</p>
                    </div>
                </div>
                </div>
                <div className="col-md-3 mbox">
                <div className="card" style={{width:"18rem"}}>
                    <img src="/assets/img/homepage/ReadyPossession.jpg" className="card-img-top" style={{height:"140px"}} alt="ReadyPossession"/>
                    <div className="card-body">
                        <p className="card-text subtitle"> Ready Possession or Pre-owned Homes</p>
                    </div>
                </div>
                </div>
                <div className="col-md-3 mbox">
                <div className="card" style={{width:"18rem"}}>
                    <img src="/assets/img/homepage/construction.jpg" className="card-img-top" style={{height:"140px"}} alt="bungalow"/>
                    <div className="card-body">
                        <p className="card-text subtitle"> Constructing new Bungalows/Raw Houses</p>
                    </div>
                </div>
                </div>
                <div className="col-md-3 mbox">
                <div className="card" style={{width:"18rem"}}>
                    <img src="/assets/img/homepage/NewBungalow.jpg" className="card-img-top" style={{height:"140px"}} alt="rowhouse"/>
                    <div className="card-body" style={{paddingBottom:"40px"}}>
                        <p className="card-text subtitle"> Takeover of Existing Home Loans</p>
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default WeOffer;