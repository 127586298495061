import { Container, Row, Col } from "react-bootstrap";
import "./DrivenByInnovation.css";

function DrivenByInnovation() {
  return (
    <Container
      fluid
      style={{ backgroundColor: "#d9d9d94d" }}
      className="p-50"
    >
      <Row className="text-white pt-5 px-5">
        <Col xs={12} md={8}>
          <h2 className="mb-3 driv-heading" style={{color:"#072c5e"}}>
          <u className="underLine">Validate your Trust with Data</u>
          </h2>
          <p className="text" style={{color:"#2f3131"}}>
          Loan is not free, so also your Trust.
          </p>
        </Col>
      </Row>
      <Row className="text-white pb-5 mb-4 my-5 px-5" style={{textAlign:"center",justifyContent: "space-around"}}>
        <div className="card myCard">
          <p className="mb-4 mt-4 heading-txt" style={{color:"rgb(7 44 94)"}}>
            <span className=""> </span> 12Y
            <span class="plus">+</span>
          </p>
          <p className="text">Group experience</p>
        </div>
        <div className="card myCard">
          <p className="mb-4 mt-4 heading-txt" style={{color:"rgb(7 44 94)"}}>
            <span className=""> </span> ₹300Cr
            <span class="plus">+</span>
          </p>
          <p className="text">Housing value created</p>
        </div>
        <div  className="card myCard">
          <p className="mb-4 mt-4 heading-txt" style={{color:"rgb(7 44 94)"}}>
            <span className=""> </span> 3000
            <span class="plus">+</span>
          </p>
          <p className="text">Families benefitted</p>
        </div>
        <div className="card myCard">
          <p className="mb-4 mt-4 heading-txt" style={{color:"rgb(7 44 94)"}}>
            <span className=""> </span> 10
            <span class="plus">+</span>
          </p>
          <p className="text">Cities covered</p>
        </div>
      </Row>
    </Container>
  );
}

export default DrivenByInnovation;
