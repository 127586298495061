import React from "react";
import { useState, useEffect } from "react";
import  swal  from "sweetalert";
import Axios from "../../utils/axiosInstance";


const Timer = (props) => {
  const { initialMinute = 2, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <button
          type="button"
          className="btn btn-primary authentication__login__form__submit-button"
          onClick={async () => {
            const data = {
              isd_code: "+91",
              mobile: props.mobile,
            };
            console.log("data", data);
            return await Axios.post(`admin/auth/resend_otp`, data)
              .then((result) => {
                swal(
                  "Great",
                  "OTP sent to registered mobile number successfully",
                  "success"
                );
                setMinutes(2);
                setSeconds(0);
                return Promise.resolve();
              })
              .catch((result) => {
                swal(
                  "Failed",
                  "Something went wrong please check your internet",
                  "error"
                );
              });
          }}
        >
          Resend OTP
        </button>
      ) : (
        <p>
          Resend OTP in 0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </div>
  );
};

export default Timer;
