import "./Dashboard.css";

import React, { PureComponent } from "react";

import { getAllApplication } from "../../Service/getAllApplication";
import { getEmployeeList } from "../../Service/getEmployeeList";

class DashboardSuperadmin extends PureComponent {
  state = {
    completed_status: 0,
    no_assign_employee: 0,
    no_not_assign_employee: 0,
    pending_status: 0,
    total_application: 0,
    employee: 0,
  };

  async componentDidMount() {
    let allapplicant = await getAllApplication();
    console.log("All applicant", allapplicant);
    let result = await getEmployeeList();
    console.log("payloadresult", result);
    this.setState({
      completed_status: allapplicant.data.payload.completed_status,
      no_assign_employee: allapplicant.data.payload.no_assign_employee,
      no_not_assign_employee: allapplicant.data.payload.no_not_assign_employee,
      pending_status: allapplicant.data.payload.pending_status,
      total_application: allapplicant.data.payload.total_application,
      employee: result.data.payload.length
    });
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );
  render() {
    return (
      <>
        <div className="animated fadeIn">
          <div className="header-body">
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">Employee</h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.employee}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow react_icons">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Alloted To Employees
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.no_assign_employee} <span className="h5 text-dark mb-0">Applications</span>
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Unalloted To Employees
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.no_not_assign_employee} <span className="h5 text-dark mb-0">Applications</span>
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-users"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Total Application
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.total_application}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Applicatilon Completed
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.completed_status}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Application Pending
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.pending_status}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-address-book"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">Query</h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.issue_count}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fa fa-comments-o"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-xl-4">
                <div className="card-stats mb-4 mb-xl-4 card">
                  <div
                    className="card-body"
                    onClick={() => this.props.history.push("/contact_us")}
                  >
                    <div className="row">
                      <div className="col px-2">
                        <h5 className="text-uppercase mb-2 card-title">
                          Contact Us
                        </h5>
                        <span className="h2 text-primary mb-0">
                          {this.state.issue_count}
                        </span>
                      </div>
                      <div className="col-auto col px-2">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <MdContactMail className="react_icons" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DashboardSuperadmin;
