import "./ForgotPassword.scss";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import Axios from "../../../utils/axiosInstance";
import Illustration3 from "../../../assets/auth/illustrationForgotPassword.svg";
import { Link } from "react-router-dom";
import Logo5 from "../../../assets/auth/logo-5.jpg";
import swal from "sweetalert";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignupSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Required"),
});

export default class ForgotPassword extends Component {
  state = {
    mobileNumber: "",
  };

  render() {
    return (
      <div className="">
        <div className="withLogo">
          <div className="text-center withLogo__left">
            <img src={Logo5} alt="logo" className="withLogo__logo" />
            <img
              src={Illustration3}
              alt="Login_Image"
              className="withLogo_lef_image"
            />
          </div>
          <Formik
            initialValues={{
              isdCode: "+91",
              mobileNumber: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values) => {
              const data = {
                isd_code: "+91",
                mobile: values.mobileNumber,
              };
              this.setState({ mobileNumber: values.mobileNumber });
              console.log("data", data);
              return await Axios.post(`admin/auth/forgot_password`, data)
                .then((result) => {
                  swal(
                    "Great",
                    "OTP sent successfully on entered mobile number",
                    "success"
                  );
                  this.props.history.push({
                    pathname: `/forgot-otp`,
                    state: {
                      applicant_mobile: this.state.mobileNumber,
                    },
                  });
                  return Promise.resolve();
                })
                .catch((result) => {
                  swal("Failed", "Enterd wrong mobile number", "error");
                });
            }}
          >
            {({ errors, touched }) => (
              <div className="withLogo__right authentication__forgotpassword-wrapper">
                <div className="authentication__forgotpassword-container">
                  <p className="text-center authentication__forgotpassword-paragraph">
                    Please provide your phone number to receive otp to reset
                    your password.
                  </p>
                  <Form className="authentication__forgotpassword-form">
                    <div className="form-group">
                      <div className="row justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__forgotpassword-form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <label htmlFor="mobileNumber">
                                Mobile Number
                              </label>
                            </div>
                            <div className="col-3">
                              <Field
                                className="form-control authentication__register__form__country-code"
                                name="isdCode"
                              />
                            </div>
                            <div className="col-9">
                              <Field
                                className="form-control"
                                name="mobileNumber"
                                maxLength="10"
                              />
                              {errors.mobileNumber && touched.mobileNumber ? (
                                <div className="error__msg">
                                  {errors.mobileNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="btn btn-primary authentication__forgotpassword__form__submit-button"
                        type="submit"
                      >
                        Submit
                      </button>
                      <p className="authentication__forgotpassword__to-register">
                        <span className="mx-1">Go back to</span>
                        <Link to="./mobile-login">
                          <strong>Login</strong>
                        </Link>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
