import "./Promotor.css";

import React, { Component } from "react";

import { Tabs, Tab } from "react-bootstrap";
import SmeLoanPromotor from "./SmeLoanPromotor";
import ApfBuilderPromotor from "./ApfBuilderPromotor";

export default class Promotor extends Component {
  render() {
    return (
      <>
        <div className="mt-7 container-fluid">
          <div className="row">
            <div className="col">
              <div className="shadow card">
                <div className="card-body">
                  <Tabs defaultActiveKey="SME_Loan">
                    <Tab eventKey="SME_Loan" title="SME Loan">
                      <div className="tab-item-wrapper">
                        <SmeLoanPromotor />
                      </div>
                    </Tab>
                    <Tab eventKey="APF_Builder" title="APF Builder">
                      <div className="tab-item-wrapper">
                        <ApfBuilderPromotor />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
