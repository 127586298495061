import "./ForgotOtp.scss";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import Axios from "../../../utils/axiosInstance";
import Illustration1 from "../../../assets/auth/illustrationLoginPage.svg";
import Logo5 from "../../../assets/auth/logo-5.jpg";
import Timer from "../Timer";
import swal from "sweetalert";

const SignupSchema = Yup.object().shape({
  otp: Yup.string().required("Required"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

export default class ForgotOtp extends Component {
  state = {
    mobile: "",
  };

  async componentDidMount() {
    try {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.applicant_mobile
      ) {
        let mobileno =
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.applicant_mobile;
        this.setState({ mobile: mobileno }, () => {
          console.log("propsmoblie", mobileno);
          console.log("moblie", this.state.mobile);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="">
        <div className="withLogo">
          <div className="text-center withLogo__left">
            <img src={Logo5} alt="logo" className="withLogo__logo" />
            <img
              src={Illustration1}
              alt="Login_Image"
              className="withLogo_lef_image"
            />
          </div>
          <Formik
            initialValues={{
              otp: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values) => {
              const data = {
                type: "FORGOT_PASSWORD",
                isd_code: "+91",
                mobile: this.state.mobile,
                otp: values.otp,
                password: values.password,
              };
              console.log("data", data);
              console.log("mobile", this.state.mobile);
              return await Axios.post(`admin/auth/verify_otp`, data)
                .then((result) => {
                  swal("Great", "Password updated successfully", "success");
                  this.props.history.push({
                    pathname: `/login`,
                  });
                  return Promise.resolve();
                })
                .catch((result) => {
                  swal("Failed", "Enterd wrong OTP", "error");
                });
            }}
          >
            {({ errors, touched }) => (
              <div className="withLogo__right authentication__login-wrapper">
                <div className="authentication__login-container">
                  <h1 className="text-center authentication__login-heading">
                    Welcome to Faceless Loans
                  </h1>
                  <Form className="authentication__login-form">
                    <div className="form-group">
                      <div className="row authentication__login__form__row-2 justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__login__form-group">
                          <label htmlFor="otp">Enter OTP</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="otp"
                          />
                          {errors.otp && touched.otp ? (
                                <div className="error__msg">
                                  {errors.otp}
                                </div>
                              ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row authentication__login__form__row-2 justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__login__form-group">
                          <label htmlFor="otp">Enter New Password</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="password"
                          />
                          {errors.password && touched.password ? (
                                <div className="error__msg">
                                  {errors.password}
                                </div>
                              ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn btn-primary authentication__login__form__submit-button"
                      >
                        Verify
                      </button>
                      <p className="authentication__login__to-register">
                        <Timer mobile={this.state.mobile}/>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
