import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./HomeLoan.css";

function BalanceTransfer({ onDataUpdate }) {
  const [principalOutstanding, setPrincipalOutstanding] = useState(10000);
  const [existingInterestRate, setExistingInterestRate] = useState(8);
  const [existingTenure, setExistingTenure] = useState(5);
  const [newInterestRate, setNewInterestRate] = useState(8);
  const [newTenure, setNewTenure] = useState(5);
  // const [lifetimeSavings, setLifetimeSavings] = useState("6386");
  // const [existingMonthlyEMI, setExistingMonthlyEMI] = useState("763");
  // const [proposedMonthlyEMI, setProposedMonthlyEMI] = useState("772");
  // const [savingInMonthlyEMI, setSavingInMonthlyEMI] = useState("0");

  //Calculation

  const calculateSavings = () => {
    const existingLoanAmount = parseFloat(principalOutstanding);
    //existing monthly interest rate
    const existingRate = parseFloat(existingInterestRate / 12) / 100;
    //existing tenure in months
    const existingTerm = parseInt(existingTenure * 12);
    const newRate = parseFloat(newInterestRate / 12) / 100;
    const newTerm = parseInt(newTenure * 12);

    // Calculation for existingMonthlyEMI
    const power = Math.pow(1 + existingRate, existingTerm);
    const existingMonthlyEMI =
      (existingLoanAmount * existingRate * power) / (power - 1);

    // Calculation for proposedMonthlyEMI
    const powerProposed = Math.pow(1 + newRate, newTerm);
    const proposedMonthlyEMI =
      (existingLoanAmount * newRate * powerProposed) / (powerProposed - 1);
    //Saving in monthly emi
    const savingInMonthlyEMI =
      existingMonthlyEMI - proposedMonthlyEMI > 0
        ? existingMonthlyEMI - proposedMonthlyEMI
        : 0;
    //Life time saving
    const cashflowSavings =
      existingMonthlyEMI * existingTerm - proposedMonthlyEMI * newTerm > 0
        ? existingMonthlyEMI * existingTerm - proposedMonthlyEMI * newTerm
        : 0;

    // setLifetimeSavings(cashflowSavings);
    // setSavingInMonthlyEMI(totalSavings);

    // Pass the relevant data to the parent component

    onDataUpdate(
      cashflowSavings.toFixed(2),
      existingMonthlyEMI.toFixed(2),
      proposedMonthlyEMI.toFixed(2),
      savingInMonthlyEMI.toFixed(2)
    );
  };

  useEffect(() => {
    calculateSavings();
  }, [
    principalOutstanding,
    existingInterestRate,
    existingTenure,
    newInterestRate,
    newTenure,
  ]);

  //New loan Section(NewData)
  const handleDecreaseNewInterest = () => {
    if (newInterestRate > 6.5) {
      setNewInterestRate(newInterestRate - 0.1);
    }
  };

  const handleIncreaseNewInterest = () => {
    if (newInterestRate < 15) {
      setNewInterestRate(newInterestRate + 0.1);
    }
  };

  const handleDecreaseNewTenure = () => {
    if (newTenure > 1) {
      setNewTenure(newTenure - 1);
    }
  };
  const handleIncreaseNewTenure = () => {
    if (newTenure < 30) {
      setNewTenure(newTenure + 1);
    }
  };

  //existing data
  const handleDecrease = () => {
    if (existingInterestRate > 6.5) {
      setExistingInterestRate(existingInterestRate - 0.1);
    }
  };

  const handleIncrease = () => {
    if (existingInterestRate < 14.9) {
      setExistingInterestRate(existingInterestRate + 0.1);
    }
  };

  const handleDecreaseExistingTenure = () => {
    if (existingTenure > 1) {
      setExistingTenure(existingTenure - 1);
    }
  };
  const handleIncreaseExistingTenure = () => {
    if (existingTenure < 30) {
      setExistingTenure(existingTenure + 1);
    }
  };

  const handlePrincipalOutstanding = (e) => {
    const value = e.target.value;
    setPrincipalOutstanding(value);
  };

  return (
    <Container>
      <Row ClassName="ml-2">
        <Col className="border-right">
          <Row>
            <Col className="ml-3">
              <h4>Current Loan</h4>
              <label className="font-sarabun font-weight-500">Principal Outstanding</label>

              <div className="input-group w100">
                <span className="input-group-text font-default">₹</span>
                <input
                  inputMode="numeric"
                  value={principalOutstanding}
                  onChange={handlePrincipalOutstanding}
                  className="input form-control"
                />
              </div>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <label
                for="principaloutstanding"
                class="mb-3 col-12 font-sarabun font-weight-500"
              >
                Interest Rate (% p.a.)
              </label>
              <label for="intrst-rate" class="col-12">
                <div class="input-group mb-3 w210">
                  <button
                    onClick={handleDecrease}
                    class="input-group-text fw-semi-bold"
                  >
                    -
                  </button>
                  <input
                    inputMode="numeric"
                    name="interest_rate"
                    formcontrolname="interest_rate"
                    autocomplete="off"
                    placeholder
                    min="6.5"
                    max="15"
                    numberonly
                    class="form-control text-center "
                    value={existingInterestRate.toFixed(2)}
                    onChange={(e) => setExistingInterestRate(e.target.value)}
                  />
                  <button
                    class="input-group-text fw-semi-bold"
                    onClick={handleIncrease}
                  >
                    +
                  </button>
                </div>
              </label>
            </Col>
            <Col>
              <label
                for="principaloutstanding"
                class="mb-3 col-12 font-sarabun font-weight-500"
              >
                Tenure (years)
                <br />
              </label>

              <label for="existingTenuree-year" class="col-12">
                <div class="input-group mb-3 w-210">
                  <button
                    onClick={handleDecreaseExistingTenure}
                    class="input-group-text fw-semi-bold"
                  >
                    -
                  </button>
                  <input
                    inputMode="numeric"
                    name="existingTenuree"
                    formcontrolname="existingTenuree"
                    maxlength="2"
                    min="1"
                    max="30"
                    numberonly
                    class="form-control text-center "
                    value={existingTenure}
                    onChange={(e) => setExistingTenure(e.target.value)}
                  />
                  <button
                    class="input-group-text fw-semi-bold"
                    onClick={handleIncreaseExistingTenure}
                  >
                    +
                  </button>
                </div>
              </label>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <h4>New Home Loan</h4>

            <Col>
              <label
                for="principaloutstanding"
                class="mb-3 col-12 font-sarabun font-weight-500"
              >
                Interest Rate (% p.a.)
              </label>
              <label for="intrst-rate" class="col-12">
                <div class="input-group mb-3 w210">
                  <button
                    onClick={handleDecreaseNewInterest}
                    class="input-group-text fw-semi-bold"
                  >
                    -
                  </button>
                  <input
                    inputMode="numeric"
                    name="interest_rate"
                    formcontrolname="interest_rate"
                    autocomplete="off"
                    placeholder
                    min="6.5"
                    max="15"
                    numberonly
                    class="form-control text-center "
                    value={newInterestRate.toFixed(2)}
                    onChange={(e) => setNewInterestRate(e.target.value)}
                  />
                  <button
                    class="input-group-text fw-semi-bold"
                    onClick={handleIncreaseNewInterest}
                  >
                    +
                  </button>
                </div>
              </label>
            </Col>
            <Col>
              <label
                for="principaloutstanding"
                class="mb-3 col-12 font-sarabun font-weight-500"
              >
                Tenure (years)
                <br />
              </label>

              <label for="newTenure-year" class="col-12">
                <div class="input-group mb-3 w-210">
                  <button
                    onClick={handleDecreaseNewTenure}
                    class="input-group-text fw-semi-bold"
                  >
                    -
                  </button>
                  <input
                    inputMode="numeric"
                    name="newTenure"
                    formcontrolname="newTenure"
                    maxlength="2"
                    min="1"
                    max="30"
                    numberonly
                    class="form-control text-center "
                    value={newTenure}
                    onChange={(e) => setNewTenure(e.target.value)}
                  />
                  <button
                    class="input-group-text fw-semi-bold"
                    onClick={handleIncreaseNewTenure}
                  >
                    +
                  </button>
                </div>
              </label>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default BalanceTransfer;
