import "./ApplicationExport.css";

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";

import Axios from "../../utils/axiosInstance";
import swal from "sweetalert";
import fileSaver from "file-saver";
import GenericPdfDownloader from "./GenericPdfDownloader";

export default class ApplicationExport extends Component {
  state = {
    applicant_personal_details: {},
    loader: false,
    allshow: false,
    public_id: "",
    applicant_address_details_permanent: [],
    applicant_address_details_correspondence: [],
    applicant_employement_details: [],
    applicant_bank_details: [],
    applicant_property_details: [],
    applicant_document_data: [],
    coapplicantOne_personal_details: [],
    coapplicantOne_address_details_permanent: [],
    coapplicantOne_address_details_correspondence: [],
    coapplicantOne_employement_details: [],
    coapplicantOne_bank_details: [],
    coapplicantTwo_personal_details: [],
    coapplicantTwo_address_details_permanent: [],
    coapplicantTwo_address_details_correspondence: [],
    coapplicantTwo_employement_details: [],
    coapplicantTwo_bank_details: [],
    coapplicant_document_data: [],
  };

  async componentDidMount() {
    try {
      console.log("props ID", this.props.location);
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.public_id
      ) {
        let public_app =
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.public_id;
        this.setState({ public_id: public_app }, async () => {
          console.log("public_app", this.state.public_id);
          let token = localStorage.getItem("token");
          return await Axios.get(
            `admin/dashboard/get_application/${this.state.public_id}`,
            {
              headers: { "x-auth-token": `${token}` },
            }
          )
            .then((result) => {
              console.log("Single Application", result);
              if (
                result &&
                result.data &&
                result.data.payload &&
                result.data.payload.length !== 0
              ) {
                this.setState({
                  applicant_personal_details: result.data.payload[0].applicant,
                  applicant_address_details_permanent:
                    result.data.payload[0].applicant_address_data[0],
                  applicant_address_details_correspondence:
                    result.data.payload[0].applicant_address_data[1],
                  applicant_employement_details:
                    result.data.payload[0].applicant_employement_data[0],
                  applicant_bank_details:
                    result.data.payload[0].applicant_bank_data[0],
                  applicant_property_details:
                    result.data.payload[0].applicant_property_data[0],
                  applicant_document_data:
                    result.data.payload[0].applicant_document_data,
                  coapplicantOne_personal_details:
                    result.data.payload[0].co_applicant_data[0],
                  coapplicantOne_address_details_permanent:
                    result.data.payload[0].co_applicant_address_data[0],
                  coapplicantOne_address_details_correspondence:
                    result.data.payload[0].co_applicant_address_data[1],
                  coapplicantOne_employement_details:
                    result.data.payload[0].co_applicant_employement_data[0],
                  coapplicantOne_bank_details:
                    result.data.payload[0].co_applicant_bank_data[0],
                  coapplicantTwo_personal_details:
                    result.data.payload[0].co_applicant_data[1],
                  coapplicantTwo_address_details_permanent:
                    result.data.payload[0].co_applicant_address_data[2],
                  coapplicantTwo_address_details_correspondence:
                    result.data.payload[0].co_applicant_address_data[3],
                  coapplicantTwo_employement_details:
                    result.data.payload[0].co_applicant_employement_data[1],
                  coapplicantTwo_bank_details:
                    result.data.payload[0].co_applicant_bank_data[1],
                  coapplicant_document_data:
                    result.data.payload[0].co_applicant_document_data,
                });
              }
              return Promise.resolve();
            })
            .catch((result) => {
              swal("Sorry", "User not added data completely", "error");
              this.props.history.push({
                pathname: `/view_applications`,
              });
            });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  downloadAllFiles = async () => {
    if (this.state.applicant_document_data.length !== 0) {
      this.state.applicant_document_data.map((obj) => {
        return fileSaver.saveAs(obj.file_url, obj.file_name);
      });
      if (this.state.coapplicant_document_data.length !== 0) {
        this.state.coapplicant_document_data.map((obj) => {
          return fileSaver.saveAs(obj.file_url, obj.file_name);
        });
      }
    } else {
      swal("Sorry", "User not uploaded documents", "error");
    }
  };

  render() {
    const { loader } = this.state;
    return (
      <>
        {loader ? (
          <div className="loader"></div>
        ) : (
          <div className="mt-7 container-fluid">
            <div className="row">
              <div className="order-xl-1 col-xl-12">
                <div className="shadow card">
                  <div className="card-body">
                    <Tabs defaultActiveKey="Applicant">
                      <Tab eventKey="Applicant" title="Applicant Details">
                        <div className="tab-item-wrapper">
                          <div className="userList__btn__wrapper">
                            <GenericPdfDownloader
                              downloadFileName={`${this.state.applicant_personal_details.applicant_first_name}_${this.state.applicant_personal_details.applicant_last_name}_Applicant_Details`}
                              rootElementId="applicantId"
                            />
                          </div>
                          <br />
                          <div id="applicantId" style={{ padding: "15px" }}>
                            <h4 className="heading-small font-weight-bold mb-4">
                              Applicant Details
                            </h4>
                            {/*-------------------- Applicant Personal Details Starts Here --------------------*/}
                            <h5>Applicant Personal Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Name of Applicant:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : `${this.state.applicant_personal_details.applicant_first_name} ${this.state.applicant_personal_details.applicant_middle_name} ${this.state.applicant_personal_details.applicant_last_name}`}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Gender:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_gender}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Marital Status:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_marital_status}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Dependents:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .dependent}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Father Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details
                                        .applicant_father_first_name === null ||
                                      this.state.applicant_personal_details
                                        .applicant_father_first_name ===
                                        undefined ||
                                      this.state.applicant_personal_details
                                        .applicant_father_first_name === "" ||
                                      this.state.applicant_personal_details
                                        .applicant_father_middle_name ===
                                        null ||
                                      this.state.applicant_personal_details
                                        .applicant_father_middle_name ===
                                        undefined ||
                                      this.state.applicant_personal_details
                                        .applicant_father_middle_name === "" ||
                                      this.state.applicant_personal_details
                                        .applicant_father_last_name === null ||
                                      this.state.applicant_personal_details
                                        .applicant_father_last_name ===
                                        undefined ||
                                      this.state.applicant_personal_details
                                        .applicant_father_last_name === ""
                                        ? ""
                                        : `${this.state.applicant_personal_details.applicant_father_first_name} ${this.state.applicant_personal_details.applicant_father_middle_name} ${this.state.applicant_personal_details.applicant_father_last_name}`}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Contact Details:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_mobile}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Communication Email Id:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text text-lowercase">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_email}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Date of Birth:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_DOB}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Qualification:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_qualification}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Pan Card No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_pan_number}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Are You Resident of India?:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details
                                        .applicant_residence === null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .applicant_residence}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Monthly Income:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .monthly_income}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Monthly EMI:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .monthly_emi}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Required Loan Amount:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .loan_amount}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Bank Partner:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_personal_details ===
                                        null ||
                                      this.state.applicant_personal_details ===
                                        undefined ||
                                      this.state.applicant_personal_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_personal_details
                                            .bank_with_partner}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Applicant Personal Details Ends Here --------------------*/}

                            {/*-------------------- Current Residential Address Details Starts Here --------------------*/}
                            <h5>Current Residential Address Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Address Type:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .address_type}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Address Type Category:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .address_type_category}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Flat/ Door No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        "" ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .flat_door_no}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Name of Premises/ Building:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .building_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Road/ Street:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .road}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Taluka/ Sub-Division:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .district}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Country:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .country}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">State:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .state}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Town/ City:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .city}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Pincode:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_permanent ===
                                        null ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_permanent ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_permanent
                                            .pincode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Current Residential Address Details Ends Here --------------------*/}

                            {/*-------------------- Current Correspondence Address Details Starts Here --------------------*/}
                            <h5>Current Correspondence Address Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Address Type:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .address_type}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Address Type Category:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .address_type_category}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Flat/ Door No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        "" ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .flat_door_no}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Name of Premises/
                                        Building:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .building_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Road/ Street:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .road}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Taluka/ Sub-Division:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .district}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Country:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .country}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence State:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .state}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Town/ City:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .city}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Correspondence Pincode:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_address_details_correspondence ===
                                        null ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        undefined ||
                                      this.state
                                        .applicant_address_details_correspondence ===
                                        ""
                                        ? ""
                                        : this.state
                                            .applicant_address_details_correspondence
                                            .pincode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Current Correspondence Address Details Ends Here --------------------*/}

                            {/*-------------------- Employment Details Starts Here --------------------*/}
                            <h5>Employment Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Occupation:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .occupation}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Employer Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .employer_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Designation:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .designation}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Department:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .department}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Retirement Age
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .retirement_age}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Phone No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .employer_phone}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Official Email:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text text-lowercase">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .employer_email}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Total Years of Experience:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .total_experince}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Employment Details Ends Here --------------------*/}

                            {/*-------------------- Employer Address Starts Here --------------------*/}
                            <h5>Employer Address</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Unit Office No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .unit_no}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Name of Premises/Building:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .building_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Road/Street:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details.road}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Land Mark/Post Office Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .address}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Taluka/Sub-Division:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .district}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Country:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .country}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">State:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .state}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Town/City:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details.city}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Pincode:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state
                                        .applicant_employement_details ===
                                        null ||
                                      this.state
                                        .applicant_employement_details ===
                                        undefined ||
                                      this.state
                                        .applicant_employement_details === ""
                                        ? ""
                                        : this.state
                                            .applicant_employement_details
                                            .pincode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Employer Address Ends Here --------------------*/}

                            {/*-------------------- Bank Details Starts Here --------------------*/}
                            <h5>Bank Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Account Type:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_account_type}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Account No:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_account_no}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">IFSC No:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_ifsc}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">MICR:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_micr}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">Name:</p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Bank Address:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_address}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Bank Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_bank_details ===
                                        null ||
                                      this.state.applicant_bank_details ===
                                        undefined ||
                                      this.state.applicant_bank_details === ""
                                        ? ""
                                        : this.state.applicant_bank_details
                                            .bank_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/*-------------------- Bank Details Ends Here --------------------*/}

                            {/*-------------------- Property Details Starts Here --------------------*/}
                            <h5>Property Details</h5>
                            <div className="pl-lg-4">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Property Location:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_property_details ===
                                        null ||
                                      this.state.applicant_property_details ===
                                        undefined ||
                                      this.state.applicant_property_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_property_details
                                            .property_location}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Builder Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_property_details ===
                                        null ||
                                      this.state.applicant_property_details ===
                                        undefined ||
                                      this.state.applicant_property_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_property_details
                                            .builder_name}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="newexport-wrapper">
                                    <div className="newexport">
                                      <p className="newexport-head">
                                        Property Name:
                                      </p>
                                    </div>
                                    &nbsp;
                                    <p className="newexport-text">
                                      {this.state.applicant_property_details ===
                                        null ||
                                      this.state.applicant_property_details ===
                                        undefined ||
                                      this.state.applicant_property_details ===
                                        ""
                                        ? ""
                                        : this.state.applicant_property_details
                                            .property_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*-------------------- Property Details Ends Here --------------------*/}
                        </div>
                      </Tab>
                      {/*--------------------------------------------------- Applicant Details Ends Here ---------------------------------------------------*/}

                      {/*--------------------------------------------------- Co-Applicant One Details Starts Here ---------------------------------------------------*/}
                      {this.state.applicant_personal_details.dependent === 1 ||
                      this.state.applicant_personal_details.dependent === 2 ? (
                        <Tab
                          eventKey="Co-ApplicantOne"
                          title="Co-Applicant One Details"
                        >
                          <div className="tab-item-wrapper">
                            <div className="userList__btn__wrapper">
                              <GenericPdfDownloader
                                downloadFileName={`${this.state.coapplicantOne_personal_details.co_applicant_first_name}_${this.state.coapplicantOne_personal_details.co_applicant_last_name}_Coapplicant_Details`}
                                rootElementId="coapplicantOneId"
                              />
                            </div>
                            <br />
                            <div
                              id="coapplicantOneId"
                              style={{ padding: "15px" }}
                            >
                              <h4 className="heading-small font-weight-bold mb-4">
                                Co-Applicant One Details
                              </h4>
                              {/*-------------------- Co-Applicant One Personal Details Starts Here --------------------*/}
                              <h5>Co-Applicant Personal Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Co-Applicant:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : `${this.state.coapplicantOne_personal_details.co_applicant_first_name} ${this.state.coapplicantOne_personal_details.co_applicant_middle_name} ${this.state.coapplicantOne_personal_details.co_applicant_last_name}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Gender:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_gender}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Marital Status:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_marital_status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Relationship with applicant:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_relationship}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Father Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : `${this.state.coapplicantOne_personal_details.co_applicant_father_first_name} ${this.state.coapplicantOne_personal_details.co_applicant_father_middle_name} ${this.state.coapplicantOne_personal_details.co_applicant_father_last_name}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Contact Details:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_mobile}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Communication Email Id:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text text-lowercase">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Date of Birth:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_DOB}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Qualification:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_qualification}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pan Card No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_pan_number}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Are You Resident of India?:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .co_applicant_residence}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Monthly Income:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .monthly_income}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Monthly EMI:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_personal_details
                                              .monthly_emi}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Personal Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Current Residential Address Details Starts Here --------------------*/}
                              <h5>
                                Co-Applicant Current Residential Address Details
                              </h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .address_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type Category:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .address_type_category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Flat/ Door No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .flat_door_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Premises/ Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Road/ Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .road}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Taluka/ Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">State:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Town/ City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_permanent
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Current Residential Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Current Correspondence Address Details Starts Here --------------------*/}
                              <h5>
                                Co-Applicant Current Correspondence Address
                                Details
                              </h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .address_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type Category:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .address_type_category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Flat/ Door No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .flat_door_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Name of Premises/
                                          Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Road/ Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .road}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Taluka/ Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence State:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Town/ City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_address_details_correspondence
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Current Correspondence Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Employment Details Starts Here --------------------*/}
                              <h5>Co-Applicant Employment Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Occupation:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .occupation}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Employer Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .employer_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Designation:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .designation}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Department:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .department}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Retirement Age
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .retirement_age}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Phone No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .employer_phone}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Official Email:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text text-lowercase">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .employer_email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Total Years of Experience:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .total_experince}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Employment Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Employment Address Details Starts Here --------------------*/}
                              <h5>Employer Address</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Unit Office No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .unit_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Premises/Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Road/Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .road}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Land Mark/Post Office Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .address}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Taluka/Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">State:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Town/City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_employement_details
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Employment Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Bank Details Starts Here --------------------*/}
                              <h5>Co-Applicant Bank Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Account Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_account_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Account No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_account_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          IFSC No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_ifsc}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">MICR:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_micr}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">Name:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Bank Address:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_address}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Bank Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantOne_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantOne_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantOne_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantOne_bank_details
                                              .bank_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/*-------------------- Co-Applicant One Bank Details Ends Here --------------------*/}
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {/*--------------------------------------------------- Co-Applicant One Details Ends Here ---------------------------------------------------*/}

                      {/*--------------------------------------------------- Co-Applicant Two Details Starts Here ---------------------------------------------------*/}
                      {this.state.applicant_personal_details.dependent === 2 ? (
                        <Tab
                          eventKey="Co-ApplicantTwo"
                          title="Co-Applicant Two Details"
                        >
                          <div className="tab-item-wrapper">
                            {/*--------------------------------------------------- Co-Applicant Two Details Starts Here ---------------------------------------------------*/}
                            <div className="userList__btn__wrapper">
                              <GenericPdfDownloader
                                downloadFileName={`${this.state.coapplicantTwo_personal_details.co_applicant_first_name}_${this.state.coapplicantTwo_personal_details.co_applicant_last_name}_Coapplicant_Details`}
                                rootElementId="coapplicantTwoId"
                              />
                            </div>
                            <br />
                            <div
                              id="coapplicantTwoId"
                              style={{ padding: "15px" }}
                            >
                              <h4 className="heading-small font-weight-bold mb-4">
                                Co-Applicant Two Details
                              </h4>
                              {/*-------------------- Co-Applicant Two Personal Details Starts Here --------------------*/}
                              <h5>Co-Applicant Personal Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Co-Applicant:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : `${this.state.coapplicantTwo_personal_details.co_applicant_first_name} ${this.state.coapplicantTwo_personal_details.co_applicant_middle_name} ${this.state.coapplicantTwo_personal_details.co_applicant_last_name}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Gender:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_gender}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Marital Status:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_marital_status}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Relationship with applicant:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_relationship}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Father Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {`${this.state.coapplicantTwo_personal_details.co_applicant_father_first_name} ${this.state.coapplicantTwo_personal_details.co_applicant_father_middle_name} ${this.state.coapplicantTwo_personal_details.co_applicant_father_last_name}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Contact Details:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_mobile}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Communication Email Id:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text text-lowercase">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Date of Birth:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_DOB}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Qualification:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_qualification}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pan Card No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_pan_number}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Are You Resident of India?:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .co_applicant_residence}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Monthly Income:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .monthly_income}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Monthly EMI:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_personal_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_personal_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_personal_details
                                              .monthly_emi}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant Two Personal Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant Two Current Residential Address Details Starts Here --------------------*/}
                              <h5>
                                Co-Applicant Current Residential Address Details
                              </h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .address_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type Category:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .address_type_category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Flat/ Door No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .flat_door_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Premises/ Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Road/ Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {
                                          this.state
                                            .coapplicantTwo_address_details_permanent
                                            .road
                                        }
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Taluka/ Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">State:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Town/ City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_permanent ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_permanent
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Current Residential Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Current Correspondence Address Details Starts Here --------------------*/}
                              <h5>
                                Co-Applicant Current Correspondence Address
                                Details
                              </h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .address_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Address Type Category:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .address_type_category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Flat/ Door No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .flat_door_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Name of Premises/
                                          Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Road/ Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .road}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Taluka/ Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence State:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Town/ City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Correspondence Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_address_details_correspondence ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_address_details_correspondence
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Current Correspondence Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Employment Details Starts Here --------------------*/}
                              <h5>Co-Applicant Employment Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Occupation:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .occupation}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Employer Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .employer_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Designation:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .designation}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Department:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .department}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Retirement Age
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .retirement_age}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Phone No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .employer_phone}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Official Email:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text text-lowercase">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .employer_email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Total Years of Experience:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .total_experince}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Employment Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Employment Address Details Starts Here --------------------*/}
                              <h5>Employer Address</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Unit Office No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .unit_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Name of Premises/Building:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .building_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Road/Street:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .road}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Land Mark/Post Office Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .address}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Taluka/Sub-Division:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .district}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Country:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .country}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">State:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .state}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Town/City:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .city}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Pincode:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_employement_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_employement_details ===
                                          ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_employement_details
                                              .pincode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              {/*-------------------- Co-Applicant One Employment Address Details Ends Here --------------------*/}

                              {/*-------------------- Co-Applicant One Bank Details Starts Here --------------------*/}
                              <h5>Co-Applicant Bank Details</h5>
                              <div className="pl-lg-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Account Type:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_account_type}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Account No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_account_no}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          IFSC No:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_ifsc}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">MICR:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_micr}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">Name:</p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Bank Address:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_address}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="newexport-wrapper">
                                      <div className="newexport">
                                        <p className="newexport-head">
                                          Bank Name:
                                        </p>
                                      </div>
                                      &nbsp;
                                      <p className="newexport-text">
                                        {this.state
                                          .coapplicantTwo_bank_details ===
                                          null ||
                                        this.state
                                          .coapplicantTwo_bank_details ===
                                          undefined ||
                                        this.state
                                          .coapplicantTwo_bank_details === ""
                                          ? ""
                                          : this.state
                                              .coapplicantTwo_bank_details
                                              .bank_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*-------------------- Co-Applicant Two Bank Details Ends Here --------------------*/}
                            </div>
                            {/*--------------------------------------------------- Co-Applicant Two Details Ends Here ---------------------------------------------------*/}
                          </div>
                        </Tab>
                      ) : (
                        ""
                      )}

                      <Tab
                        eventKey="Download_Documents"
                        title="Download Documents"
                      >
                        <div className="tab-item-wrapper">
                          <h4 className="heading-small font-weight-bold mb-4 text-center">
                            Download Documents
                          </h4>
                          <p>For Download Documents Click on Download Button</p>
                          <button
                            className="btn btn-primary font-weight-bold"
                            onClick={this.downloadAllFiles}
                          >
                            DOWNLOAD
                          </button>
                          {/* <h5>Applicant Documents</h5>
                          <div className="pl-lg-4">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-1 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-2 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Form No 16
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last 6 Months Bank Statement
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Proof of Bonus Last 2 Years
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Photo
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Aadhar Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Pan Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Address Proof
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          {/*--------------------------------------------------------------------------------------------------------
-----------------------------------------------------------------------------------------------------------  */}
                          {/* <h5>Co-Applicant One Documents</h5>
                          <div className="pl-lg-4">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-1 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-2 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Form No 16
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last 6 Months Bank Statement
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Proof of Bonus Last 2 Years
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Photo
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Aadhar Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Pan Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Address Proof
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <h5>Co-Applicant Two Documents</h5>
                          <div className="pl-lg-4">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-1 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last-2 Month Salary Slip
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Form No 16
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Last 6 Months Bank Statement
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Proof of Bonus Last 2 Years
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Photo
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Aadhar Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Pan Card
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group export-wrapper p-2">
                                  <div className="form-control-label export">
                                    Address Proof
                                    <i className="fa fa-download down"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
