import * as router from "react-router-dom";

import {
  AppAside,
  AppBreadcrumb2 as AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { activateGeod, closeGeod } from "../../Store";

import { Container } from "reactstrap";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { connect } from "react-redux";
import navigation from "../../_nav";
import navigatione from "../../_nave";
import routes from "../../routes";

class DefaultLayout extends Component {
  state = {
    Role: "",
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut(e) {
    e.preventDefault();
    this.props.activateGeod({ title: false });
    localStorage.clear();
  }

  async componentDidMount() {
    setTimeout(() => {
      let role = localStorage.getItem("role");
      this.setState({ Role: role });
    }, 100);
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              {this.state.Role === "EMPLOYEE" ? (
                <AppSidebarNav
                  navConfig={navigatione}
                  {...this.props}
                  router={router}
                />
              ) : (
                <AppSidebarNav
                  navConfig={navigation}
                  {...this.props}
                  router={router}
                />
              )}
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch forceRefresh={true}>
                  <Redirect exact from="/" to="/dashboard" />
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  geod: state.geod,
});

const mapDispatchToProps = {
  activateGeod,
  closeGeod,
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout);

export default AppContainer;
