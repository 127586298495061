import React from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import "../style.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { animateScroll as scroll } from "react-scroll";
const Footer = () => {
  const scrollToTop = () => {
    // scroll.scrollToTop({
    //   duration: 0,
    //   smooth: "easeInOutQuart",
    // });
    window.scroll(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Container
      style={{ backgroundColor: "#072c5e" }}
      fluid
      className="footer py-3"
    >
      <Row className="mt-2 px-3">
        <Col md={3}>
          <img
            src="https://www.facelessloans.com/static/media/logo-5.fb452af395071b3c9b98.jpg"
            alt="FaceLessLoan"
            height={55}
            width={160}
          />
        </Col>
        <Col md={3}>
          <h4 className="white-link1">Company</h4>
          <br />
          <Link to="/about-us" className="white-link" onClick={scrollToTop}>
            About Us
          </Link>
          <br /> <br />
          <Link to="/contact-us" className="white-link" onClick={scrollToTop}>
            Contact Us
          </Link>
          <br /> <br />
        </Col>
        <Col md={3}>
          <h4 className="white-link1">Resources</h4>
          <br />
          <HashLink style={{color:"#fff",textDecoration:"none"}} smooth to="/#home-calculator">Calculator</HashLink>
          <br /> <br />
          <Link to="/faqs" className="white-link" onClick={scrollToTop}>
            FAQs
          </Link>
          <br /> <br />
        </Col>
        <Col md={3}>
          <h4 className="white-link1">Legal</h4>
          <br />
          <Link to="/privacy" className="white-link" onClick={scrollToTop}>
          Privacy Policy
          </Link>
          <br /> <br />
          <Link to="/terms" className="white-link" onClick={scrollToTop}>
          Terms &amp; Conditions
          </Link>
          <br /> <br />
        </Col>
      </Row>
      <Row className="px-3">
        <div className="col-12 col-md-12 d-flex justify-content-end">
          <a href="/" className="mx-1">
            <i className="fa-brands fa-linkedin text-light fa-lg"></i>
          </a>

          <a href="/" className="mx-1">
            <i className="fa-brands fa-x-twitter text-light fa-lg"></i>
          </a>

          <a href="/" className="mx-1">
            <i className="fa-brands fa-instagram text-light fa-lg"></i>
          </a>

          <a href="/" className="mx-1">
            <i className="fa-brands fa-facebook text-light fa-lg"></i>
          </a>
        </div>
      </Row>
      <Row className="px-3">
        <Col md={12}>
          <hr className=" white-link1 fluid font-weight-bold" />
          <p className="text-center" style={{marginBottom: "5px"}}>
            <small className="white-link">
              All Rights Reserved. © Copyright 2023 Faceless Loans .
            </small>
          </p>
          <p className="text-center">
            <small className="white-link">
            Powered by : <a className="white-link" href="https://www.jyotirgamay.com/" target="">Jyotirgamay Solutions</a>
            </small>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
