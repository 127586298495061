import "./Login.scss";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { activateGeod, closeGeod } from "../../../Store";

import Axios from "../../../utils/axiosInstance";
import Illustration1 from "../../../assets/auth/illustrationLoginPage.svg";
import { Link } from "react-router-dom";
import Logo5 from "../../../assets/auth/logo-5.jpg";
import Select from "react-select";
import { connect } from "react-redux";
import swal from "sweetalert";

const SignupSchema = Yup.object().shape({
  emailAddress: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8).required("Required"),
  selectRole: Yup.string().required("Required"),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: [
        { value: "ADMIN", label: "Admin" },
        { value: "EMPLOYEE", label: "Employee" },
      ],
    };
  }

  render() {
    return (
      <div className="">
        <div className="withLogo">
          <div className="text-center withLogo__left">
            <img src={Logo5} alt="logo" className="withLogo__logo" />
            <img
              src={Illustration1}
              alt="Login_Image"
              className="withLogo_lef_image"
            />
          </div>
          <Formik
            initialValues={{
              emailAddress: "",
              password: "",
              selectRole: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values) => {
              const data = {
                email: values.emailAddress,
                password: values.password,
                role: values.selectRole,
              };
              console.log("Admin Login Data", data);
              return await Axios.post(`admin/auth/sign_in`, data)
                .then((result) => {
                  console.log("Admin Login Result", result);
                  this.props.activateGeod({ title: true });
                  localStorage.setItem("loginStatus", true);
                  localStorage.setItem(
                    "token",
                    result.data.payload.user.jwt_token
                  );
                  localStorage.setItem(
                    "refresh_token",
                    result.data.payload.user.refreshToken
                  );
                  localStorage.setItem(
                    "first_name",
                    result &&
                      result.data &&
                      result.data.payload &&
                      result.data.payload.user &&
                      result.data.payload.user.user &&
                      result.data.payload.user.user.first_name
                  );
                  localStorage.setItem(
                    "last_name",
                    result &&
                      result.data &&
                      result.data.payload &&
                      result.data.payload.user &&
                      result.data.payload.user.user &&
                      result.data.payload.user.user.last_name
                  );
                  localStorage.setItem(
                    "public_id",
                    result &&
                      result.data &&
                      result.data.payload &&
                      result.data.payload.user &&
                      result.data.payload.user.user &&
                      result.data.payload.user.user.public_id
                  );
                  localStorage.setItem(
                    "role",
                    result &&
                      result.data &&
                      result.data.payload &&
                      result.data.payload.user &&
                      result.data.payload.user.user &&
                      result.data.payload.user.user.role
                  );
                  swal("Success", "Login successfully", "success");
                  this.props.history.push("/");
                })
                .catch((result) => {
                  swal("Login Failed", "Check Email And Password", "error");
                });
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <div className="withLogo__right authentication__login-wrapper">
                <div className="authentication__login-container">
                  <h1 className="text-center authentication__login-heading">
                    Welcome to Faceless Loans Admin
                  </h1>
                  <Form className="authentication__login-form">
                    <div className="form-group">
                      <div className="row justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__login__form-group">
                          <label htmlFor="emailAddress">Email Address</label>
                          <Field
                            className="form-control"
                            type="email"
                            name="emailAddress"
                          />
                          {errors.emailAddress && touched.emailAddress ? (
                            <div className="error__msg">
                              {errors.emailAddress}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row authentication__login__form__row-2 justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__login__form-group">
                          <label htmlFor="password">Password</label>
                          <Field
                            className="form-control"
                            type="password"
                            name="password"
                          />
                          {errors.password && touched.password ? (
                            <div className="error__msg">{errors.password}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row justify-content-md-center">
                        <div className="col-md-6 col-xs-6 authentication__login__form-group">
                          <label htmlFor="selectRole">Role</label>

                          <Select
                            options={this.state.roleList}
                            onChange={(e) => {
                              setFieldValue("selectRole", e.value);
                            }}
                          />
                          {errors.selectRole && touched.selectRole ? (
                            <div className="error__msg">
                              {errors.selectRole}
                            </div>
                          ) : null}
                          <p className="mt-2">
                            <Link to="forgotpassword">
                              Forgot your password?
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <button
                        type="submit"
                        className="btn btn-primary authentication__login__form__submit-button"
                      >
                        Sign In
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  geod: state.geod,
});

const mapDispatchToProps = {
  activateGeod,
  closeGeod,
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
export default AppContainer;
