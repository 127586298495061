import "./ApplicationExport.css";

import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      // const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps= pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      // pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return <button className="btn btn-primary newStyle" onClick={downloadPdfDocument}><i className="fa fa-download fa-lg"></i></button>;
};

export default GenericPdfDownloader;
