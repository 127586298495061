import React from "react";
import MainNav from "../Navbar/MainNav";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import TextAnimation from './TextAnimation';
import "../style.css";
import "./BannerSection.css";

function LoginSignUp() {
  const words = ['Simple', 'Speed', 'Convenient'];
  const duration = 2000; // milliseconds
  
  return (
    <>
      {/* Navigation */}
      <Container fluid className="" style={{backgroundColor:"#072c5e"}}>
       <MainNav/>
        {/*responsive navbar*/}
        {/* Home Loans */}
        <Row className="mt-0">
          <Col style={{ backgroundColor: "#072c5e" }} sm={12}>
            <div className="container  mob-m-0 px-0 mt-5">
              <div className="row mb-5 justify-content-md-center">
                <div className="col-md-6 mt-5">
                  <h1 className="">
                    <span
                      className="type-span"
                      style={{ color: "white" }}
                    ></span>
                    <span
                      className=""
                      style={{
                        color: "white",
                        opacity: 1,
                        transitionDuration: "0.1s",
                      }}
                    ></span>
                  </h1>
                  {/* Banner heading */}
                  
                  <h1 className="title mb-4">
                  <TextAnimation words={words} duration={duration} />
                  </h1><br/><br />
                  <h1 className="title"> Home Loans</h1>
                  {/* Banner sub-heading */}
                  <p
                    style={{ color: "white" }}
                    className="sub-header mb-5 ps-3"
                  >
                    Faceless Loans is an unique platform where you can apply & get home loans with best offers 
comfortably from your home without having to go through the hassle of manually processing with 
multiple banks separately.

                   {/* <span className="banner-percentage"> @8.6%</span>*/}
                  </p>
                  {/* Banner button */}
                  <div className="nav-button">
                  
                    <Button
                      href="https://homeloan.facelessloans.com/mobile-login"
                      target=""
                      aria-label="Appy Now"
                      className="btn btn-danger font16 btn-lg text-white"
                    >
                      Apply Now
                    </Button>
                    
                  </div>
                  <div className="mt-5">
                {/*  <img
                    src="/assets/img/homepage/chevrons-right.png"
                    width="200"
                    height="auto"
                    alt=""
                    />*/}
                  </div>
                </div>
                <div className="col-md-5 banner-img">
                {<img
                    src="/assets/img/homepage/familyhome.png"
                    className=""
                    width="630px"
                    height=""
                    alt=""
                    />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginSignUp;
