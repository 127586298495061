import "./Query.css";

import React, { Component } from "react";
import moment from "moment";

import ReactPaginate from "react-paginate";
import Select from "react-select";
import Axios from "../../utils/axiosInstance";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { getContactUs } from "../../Service/getContactUs";

export default class Query extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      statusList: [
        { value: "PENDING", label: "PENDING" },
        { value: "COMPLETED", label: "COMPLETED" },
      ],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      loader: false,
      selectedStatus: "",
      token: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  async getData() {
    let result = await getContactUs();
    console.log("payloadresult", result);
    if (
      result &&
      result.data &&
      result.data.payload &&
      result.data.payload.query_data &&
      result.data.payload.query_data.length !== 0
    ) {
      const tdata = result.data.payload.query_data;
      const slice = tdata.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice,
        loader: false,
      });
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loader: true });
      this.getData();
      this.setState({ token: localStorage.getItem("token"), loader: false });
    } catch (error) {
      console.log(error);
      this.setState({ loader: false });
    }
  }

  render() {
    const { tableData, loader } = this.state;
    return (
      <>
        {loader ? (
          <div className="loader"></div>
        ) : (
          <div className="mt-7 container-fluid">
            <div className="row">
              <div className="col">
                <div className="shadow card">
                  <div className="border-0 card-header"></div>
                  <div className="table-responsive">
                    <table className="align-items-center table-flush table">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "6%" }}>Sr No</th>
                          <th style={{ width: "13%" }}>Name</th>
                          <th style={{ width: "6%" }}>Email</th>
                          <th style={{ width: "11%" }}>Mobile No</th>
                          <th style={{ width: "16%" }}>Issues</th>
                          <th style={{ width: "16%" }}>Status</th>
                          <th style={{ width: "20%" }} colSpan={2}>
                            Remark
                          </th>
                          <th style={{ width: "7%" }}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((obj, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td className="text-capitalize">{obj.name}</td>
                            <td>{obj.email}</td>
                            <td>{obj.mobile}</td>
                            <td>{obj.message}</td>
                            <td>
                              <Select
                                options={this.state.statusList}
                                onChange={(e) => {
                                  this.setState(
                                    { selectedStatus: e.value },
                                    async () => {
                                      return await Axios.put(
                                        `admin/dashboard/contact/add_remarks/${obj.form_id}`,
                                        {
                                          status: this.state.selectedStatus,
                                        },
                                        {
                                          headers: {
                                            "x-auth-token": this.state.token,
                                          },
                                        }
                                      )
                                        .then((result) => {
                                          console.log(
                                            "Status send successfully",
                                            result
                                          );
                                          swal(
                                            "Great",
                                            "Status send successfully",
                                            "success"
                                          );
                                          return Promise.resolve(
                                            window.location.reload()
                                          );
                                        })
                                        .catch((result) => {
                                          swal(
                                            "Failed",
                                            "Somthing went wrong",
                                            "error"
                                          );
                                        });
                                    }
                                  );
                                }}
                                value={
                                  this.state.statusList
                                    ? this.state.statusList.find(
                                        (option) => option.value === obj.status
                                      )
                                    : ""
                                }
                              />
                            </td>
                            <td>{obj.comments}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/query_remark",
                                  state: { form_id: obj.form_id },
                                }}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </Link>
                              <span className="tooltip-e-text">
                                Edit Remark
                              </span>
                            </td>
                            <td>
                              {moment(obj.created_at).format("yyyy-MM-DD")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="py-4 card-footer">
                    <ReactPaginate
                      previousLabel={<i className="fa fa-angle-left"></i>}
                      nextLabel={<i className="fa fa-angle-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
