import Footer from "../Home/Footer/Footer";
import MainNav from "../Home/Navbar/MainNav";
import Container from "react-bootstrap/Container";

const TermsAndConditions =()=>{
    
    return(
        <>
        <Container fluid className="mb-5" style={{backgroundColor:"#072c5e"}}>
        <MainNav/>   
        {/*responsive navbar*/}
      </Container>
      <Container style={{height:"100vh"}}>
        <div className="row justify-content-center">
            <h1 style={{textAlign:"center"}}>Terms And Conditions</h1>
            <h2 style={{textAlign:"center", color:"#737373"}}>PAGE UNDER DEVELOPMENT</h2>
            <div className="col-md-6" style={{textAlign:"center"}}>
              <img src="/assets/img/homepage/webPagedevelopment.svg" alt="" style={{width:"100%"}}/>
            </div>
            
        </div>
      </Container>
      <Footer/>
        </>
    );
}

export default TermsAndConditions;