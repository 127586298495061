import React from 'react';
import { HashLink } from "react-router-hash-link";
import "./modal.css";


function modal(props) {
  return (props.trigger) ? (
    <div className='popup'>
      <div className='inner-popup'>
      <div className='container'>
        <div className='row'>
            <div className='col-md-12'>
            <div><h2 style={{color:"#111e6c"}}> Lightning Fast Digital Process <div onClick={()=>props.setTrigger(false)}  className='btn btn-danger close-btn'>X</div></h2> </div>  
              <h4 style={{color:"#ba1f00"}}>Home Loans</h4>
              <br />

              <div className='btn btn-primary' ><HashLink smooth to="/#home-calculator"><span className='text-white' style={{textDecoration:"none"}} onClick={()=>props.setTrigger(false)} >Loan Calculator</span></HashLink></div>
            </div>
        </div>
      </div>
      </div>
       </div>
  ):"";
}

export default modal