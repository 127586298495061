import axios from "axios";

const instance = axios.create({
  // baseURL: "http://52.206.85.160/",
  baseURL: "https://api.facelessloans.com/",
  timeout: 1800000,
  headers: { "Access-Control-Allow-Origin": "*" },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log("error :" + JSON.stringify(error));
    if (error.message === "Request failed with status code 401") {
      const data = {
        token: localStorage.getItem("refresh_token"),
      };
      return await axios
        .post(`https://api.facelessloans.com/admin/auth/refresh_session`, data, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((tokenRefreshResponse) => {
          console.log(tokenRefreshResponse.data.payload.jwt_token);
          localStorage.setItem("token", tokenRefreshResponse.data.payload.jwt_token);
          window.location.reload();
          return Promise.resolve();
        });
    }
    console.log("Rest promise error");
    return Promise.reject(error);
  }
);

export default instance;
