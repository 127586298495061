import "./AddEmployee.css";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import Axios from "../../utils/axiosInstance";
import Select from "react-select";
import swal from "sweetalert";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8).required("Required"),
  phone: Yup.string()
  .matches(phoneRegExp, "Phone number is not valid")
  .required("Required"),
  selectRole: Yup.string().required("Required"),
  jobTitle: Yup.string().required("Required"),
});
export default class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleList: [
        { value: "ADMIN", label: "Admin" },
        { value: "EMPLOYEE", label: "Employee" },
      ],
    };
  }

  render() {
    return (
      <div className="userAdd__wrapper">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            isdCode: "",
            phone: "",
            selectRole: "",
            jobTitle: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            const data = {
              email: values.email,
              isd_code: "+91",
              mobile: values.phone,
              password: values.password,
              role: values.selectRole,
              first_name: values.firstName,
              last_name: values.lastName,
              designation: values.jobTitle,
              username: values.userName,
            };
            console.log("data", data);
            return await Axios.post(`admin/auth/sign_up`, data)
              .then((result) => {
                swal(
                  "Great",
                  "Added successfully",
                  "success"
                );
                window.location.reload();
                return Promise.resolve();
              })
              .catch((result) => {
                swal(
                  "Failed",
                  "Something went wrong please check your internet",
                  "error"
                );
              });
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <div className="mt-7 container-fluid">
              <div className="row">
                <div className="order-xl-1 col-xl-12">
                  <div className="shadow card">
                    <div className="card-body">
                      <Form>
                        <h4 className="heading-small font-weight-bold mb-4">
                          Add Employee
                        </h4>
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  First Name
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="firstName"
                                  placeholder="First Name"
                                />
                                {errors.firstName && touched.firstName ? (
                                  <div className="error__msg">
                                    {errors.firstName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Last Name
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="lastName"
                                  placeholder="Last Name"
                                />
                                {errors.lastName && touched.lastName ? (
                                  <div className="error__msg">
                                    {errors.lastName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Email Id
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="email"
                                  placeholder="Email Id"
                                />
                                {errors.email && touched.email ? (
                                  <div className="error__msg">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Password
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="password"
                                  placeholder="Password"
                                />
                                {errors.password && touched.password ? (
                                  <div className="error__msg">
                                    {errors.password}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Mobile No
                                </label>
                                <span className="mustRequired">*</span>
                                <div className="row">
                                  <div className="col-3">
                                    <Field
                                      className="form-control authentication__register__form__country-code"
                                      name="isdCode"
                                      value="+91"
                                    />
                                  </div>
                                  <div className="col-9">
                                    <Field
                                      className="form-control-alternative form-control"
                                      name="phone"
                                      placeholder="Phone"
                                      maxLength="10"
                                    />
                                    {errors.phone && touched.phone ? (
                                      <div className="error__msg">
                                        {errors.phone}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Role
                                </label>
                                <span className="mustRequired">*</span>
                                <Select
                                  options={this.state.roleList}
                                  onChange={(e) => {
                                    setFieldValue("selectRole", e.value);
                                  }}
                                />
                                {errors.selectRole && touched.selectRole ? (
                                  <div className="error__msg">
                                    {errors.selectRole}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Designation
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="jobTitle"
                                  placeholder="Job Title"
                                />
                                {errors.jobTitle && touched.jobTitle ? (
                                  <div className="error__msg">
                                    {errors.jobTitle}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="text-center">
                          <button type="submit" className="btn btn-primary">
                            SUBMIT
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}
