import React from "react";
import { useState,useEffect } from "react";
import Modal from "../modal/modal";
import HomeLoanCalculator from "./HomeLoanCalculator/HomeLoanCalculator";
import Footer from "./Footer/Footer";
import LoginSignUp from "./BannerSection/LoginSignUp";
import GetInPrinciple from "./GetInPrinciple/GetInPrinciple";
import BringingYou from "./BringingYou/BringingYou";
import DrivenByInnovation from "./DrivenByInnovation/DrivenByInnovation";
import FAQ from "./FAQ/FAQ";
import HomebuyerCommunity from "./HomeBuyerCommunity/HomebuyerCommunity";
import Process from "./Process/Process";
import WeOffer from "./weOffer/weOffer";

function Home() {

  const [showPopup, setShowPopup] = useState(false)
  useEffect(()=>{
      setTimeout(() => {
      setShowPopup(true);
    }, 5000);
  },[])

  return (
    <>
      
      <LoginSignUp />
      <WeOffer/>
      {/* <GetInPrinciple /> */}
      {/* <HomebuyerCommunity /> */}
      <BringingYou />
      <Process />
      <HomeLoanCalculator />
      <DrivenByInnovation />
      {/* <FAQ /> */}
      <Footer />
      <Modal trigger={showPopup} setTrigger={setShowPopup}/>
    </>
  );
}

export default Home;
