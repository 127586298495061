import React, { useState } from "react";
import MainNav from "../Home/Navbar/MainNav";
import Footer from "../Home/Footer/Footer";

import axios from "axios";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "./ContactUs.css";
import "../../assets/contactUs/contactus.jpg";
import swal from "sweetalert";

const ContactUs = () => {
  const [formData, setFormData] = useState({
      mobile: "",
      isd_code: "+91",
      message: "",
      type: "ENQUIRY",
      email: "",
      name: ""
  });
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const regex = /^[a-zA-Z ]+$/;
    let errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (!regex.test(formData.name)) {
      // Display an error message.
      errors.name ="Your name can only contain letters.";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.mobile) {
      errors.mobile = "Phone number is required";
    } else if (!/^\d+$/.test(formData.mobile)) {
      errors.mobile = "Phone number should contain only digits";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleChange = (e) => {
    //console.log(e.target);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic goes here
    const isValid = validateForm();

    if (isValid) {
     // console.log(formData);
     const url = "https://api.facelessloans.com/admin/dashboard/contact"
    //   const url = "http://34.233.243.127:3000/admin/dashboard/contact";
     // const url = "http://34.233.243.127:3000/";
      axios
        .post(url, formData)
        .then((response) => {
          swal("Great", "Added successfully", "success");
          console.log("Request sent successfully!");

         // console.log(response.data);
        })
        .catch((error) => {
          swal(
            "Failed",
            "Something went wrong please check your internet",
            "error",
          );
          console.error("An error occurred while sending the request:", error);
        });
    }
  };

  return (
    <>
      <Container fluid className="mb-5" style={{backgroundColor:"#072c5e"}}>
        <MainNav/>
        {/*responsive navbar*/}
      </Container>
      <Container id="ContactUs">
        <Row className="justify-content-center">
          <Col lg={6} md={8} className="enquiry-fromm-12 wrap-contact100">
          <div className="contact100-form-title" style={{backgroundImage: "url(assets/img/homepage/contact-us-bg.jpg)",color:"#fff"}}>
            <span style={{fontSize:"30px"}}>Contact Us</span>
            <span style={{fontSize:"18px"}}>Feel free to drop us a line below!</span>
          </div>
            <Container className="p-5">
              <div className="row justify-content-cemter">
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="form-group" controlId="name">
                  <Form.Label
                    column
                    sm={3}
                    className="col-form-label labelcolor"
                  >
                    Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      minLength={2}
                      maxLength={30}
                    />
                    {formErrors.name && (
                      <div className="error-message">{formErrors.name}</div>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="email">
                  <Form.Label
                    column
                    sm={3}
                    className="col-form-label labelcolor"
                  >
                    Email
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    {formErrors.email && (
                      <div className="error-message">{formErrors.email}</div>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mobile">
                  <Form.Label
                    column
                    sm={3}
                    className="col-form-label labelcolor"
                  >
                    Mobile No.
                  </Form.Label>

                  <Col sm={9}>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      placeholder="Enter your mobile number"
                      minLength={10}
                      maxLength={10}
                    />
                    {formErrors.mobile && (
                      <div className="error-message">{formErrors.mobile}</div>
                    )}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="message">
                  <Form.Label
                    column
                    sm={3}
                    className="col-form-label labelcolor"
                  >
                    Message
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={4}
                      placeholder="Enter your message"
                      minLength={5}
                      maxLength={100}
                    />
                    {formErrors.message && (
                      <div className="error-message">{formErrors.message}</div>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Col sm={{ span: 9, offset: 3 }}>
                    <Button type="submit" className="btnenquirycontactus mb-3">
                      SEND
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <Row className="mt-2">
          <Col md={6} lg={6} sm={12} className="mb-2">
            <div className="card border-white shadow h-100">
              <div className="card-body">
                <h4 className="">Customer Support</h4>
                <hr className=" font-weight-bold" />
                <p className="text-secondary">
                  <a
                    className="link-set text-secondary"
                    href="mailto:faceless.loans1@gmail.com 
                    "
                  >
                    faceless.loans1@gmail.com 

                  </a>{" "}
                  <span className="text-secondary">|&nbsp; </span>
                  <a
                    className="link-set text-secondary"
                    href="tel:+912241498499"
                  >
                    Phone : 9960718224
                  </a>
                  <p className="text-secondary">
                    (Mon ‐ Fri 10:00 AM ‐ 7:00 PM)
                  </p>
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={6} sm={12} className="mb-2">
            <div className="card border-white shadow h-100">
              <div className="card-body">
                <h4 className="">Registered Address</h4>
                <hr className=" font-weight-bold" />

                <p className=" text-secondary">
                  Office No. 8 & 10, Fourth Floor, Business Square, Baner-Pashan Link Road Opposite Fortune Square, Baner, Pune, Maharashtra 411021.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
