import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./MainNav.css";

function MainNav() {
  return (
    <Navbar collapseOnSelect expand="lg" className="myNav">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
        <img
                  src="/static/media/logo-5.fb452af395071b3c9b98.jpg"
                  className="facelesslogo"
                  alt="..."
                  height="55"
                  width="160"
                />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto navItems">
            <Nav.Link><HashLink className="navItem" smooth to="/">Home</HashLink></Nav.Link>
            <Nav.Link><HashLink className="navItem" smooth to="/about-us">About us</HashLink></Nav.Link>
            <Nav.Link><HashLink className="navItem" smooth to="/faqs">FAQs</HashLink></Nav.Link>
            <Nav.Link><HashLink className="navItem" smooth to="/contact-us">Contact us</HashLink></Nav.Link>
            <Nav.Link><HashLink className="navItem" smooth to="/#home-calculator">Calculator</HashLink></Nav.Link>
           
          </Nav>
          <Nav>
          <div className="btn logBtn">
                     <a href="https://homeloan.facelessloans.com/mobile-login" target="">Login</a> 
                    </div>
                    <div className="mx-3 btn signBtn">
                     <a href="https://homeloan.facelessloans.com/register" target="">Signup</a>
                    </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNav;