import './Process.css';
import Carousel from 'react-bootstrap/Carousel';

function Process() {
   
 const doubleArrow = <svg width="60px" height="60px" viewBox="0 -6.5 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
 <g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
     <g id="ui-gambling-website-lined-icnos-casinoshunter" transform="translate(-1511.000000, -158.000000)" fill="#1C1C1F" fill-rule="nonzero">
         <g id="1" transform="translate(1350.000000, 120.000000)">
             <path d="M187.812138,38.5802109 L198.325224,49.0042713 L198.41312,49.0858421 C198.764883,49.4346574 198.96954,49.8946897 199,50.4382227 L198.998248,50.6209428 C198.97273,51.0514917 198.80819,51.4628128 198.48394,51.8313977 L198.36126,51.9580208 L187.812138,62.4197891 C187.031988,63.1934036 185.770571,63.1934036 184.990421,62.4197891 C184.205605,61.6415481 184.205605,60.3762573 184.990358,59.5980789 L192.274264,52.3739093 L162.99947,52.3746291 C161.897068,52.3746291 161,51.4850764 161,50.3835318 C161,49.2819872 161.897068,48.3924345 162.999445,48.3924345 L192.039203,48.3917152 L184.990421,41.4019837 C184.205605,40.6237427 184.205605,39.3584519 184.990421,38.5802109 C185.770571,37.8065964 187.031988,37.8065964 187.812138,38.5802109 Z" id="right-arrow">

</path>
         </g>
     </g>
 </g>
</svg>
 return (
    <>
    <div className='container headline' id='process-section'>
      <div className='row'>
        <div className='col-12'>
          <div>
            <h2 className='mb-5 mt-5'><u className="underLine">How do we Operate</u></h2>
            <p>Complete your Home Loan journey in 3 simple steps.</p>
          </div>
        </div>
      </div>
    </div>
    <div className='pcDisplay' style={{textAlign:"center",paddingBottom:"50px"}}>
        <div className='container mt-5'>
          <div className='row'>

<div class="card col-md-4" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step1</span>
              <p className="dblArrow">
                
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"0px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c"}}>Enter Loan Application related Data and Documents</h5>
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo" style={{paddingBottom:"28px"}}>In the online application form enter your details and upload documents</p>
    
          </div>
      </div>
  </div>
</div>

<div class="card col-md-4" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step2</span>
              <p className="dblArrow">
                {doubleArrow}
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"0px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c"}}>Sanction <br />letter</h5>
                  <br />
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo" style={{paddingBottom:"28px"}}>get sanction letters from different banks and select the bank of your choice</p>
    
          </div>
      </div>
  </div>
</div>

<div class="card col-md-4" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step3</span>
              <p className="dblArrow">
                {doubleArrow}
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"0px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c",paddingBottom:"20px"}}>Loan Disbursement</h5>
                 <br />
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo" style={{paddingBottom:"24px"}}>complete the signing and registration, and get funds disbursed into your account.</p>
    
          </div>
      </div>
  </div>
</div>

          </div>
        </div>
    </div>

 <div className="mDisplay" style={{textAlign:"center"}}>   
<div className='container'>
  <div className='row'>
    <Carousel>
      <Carousel.Item>
      <div class="card col-md-3" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step1</span>
              <p className="dblArrow">
                {doubleArrow}
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"0px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c"}}>Enter Loan Application related Data and Documents</h5>
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo">In the online application form enter your details and upload documents</p>
    
          </div>
      </div>
  </div>
</div>
      </Carousel.Item>
      <Carousel.Item>
      <div class="card col-md-3" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step2</span>
              <p className="dblArrow">
                {doubleArrow}
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"0px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c"}}>Sanction<br/> Letter</h5>
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo">Get sanction letters from different banks and select the bank of your choice</p>
    
          </div>
      </div>
  </div>
</div>
      </Carousel.Item>

      <Carousel.Item>
      <div class="card col-md-3" style={{border:"none", boxShadow:"none"}} >
            <div className='row'>
            <div className='col-4 leftSec'><span className='number'>Step3</span>
              <p className="dblArrow">
                {doubleArrow}
              </p>
            </div>
            <div className='col-8 rightSec'>
              <div className="card-body">
              <div style={{padding:"20px 0px"}}>
                 <h5 className="card-subtitle line" style={{color:"#111e6c"}}>Loan Disbursement</h5>
                  <div className="row p-0 m-0 d-flex align-items-center">
                    <div className="col-3 bg-danger" style={{height:"6px"}}></div>
                    <div className="col-9 bg-warning" style={{height:"3px"}}></div>
                  </div>
              </div>
            <p className="card-text cardInfo">Complete the signing and registration, and get funds disbursed into your account.</p>
    
          </div>
      </div>
  </div>
</div>
      </Carousel.Item>
    </Carousel>
    </div>
    </div>
    </div>
    </>
  );
}

export default Process;
