import "./App.scss";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { activateGeod, closeGeod } from "./Store";

import DefaultLayout from "./containers/DefaultLayout";
import ForgotOtp from "./views/Pages/ForgotOtp";
import ForgotPassword from "./views/Pages/ForgotPassword";
import Login from "./views/Pages/Login";
import Page404 from "./views/Pages/Page404";
import Page500 from "./views/Pages/Page500";
import { connect } from "react-redux";
import Home from "./views/Home/Home";
import ContactUs from "./views/HomePageContactUs/ContactUs";
import FAQsPage from "./views/HomePageFAQs/FAQsPage";
import AboutUs from "./views/HomePageAboutUs/AboutUs";
import TermsAndConditions from "./views/HomePageTerms/TermsAndConditions";
import PrivacyPolicy from "./views/HomePagePrivacy/PrivacyPolicy";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const isAuthenticated = (reduxprops) => {
  console.log(
    "initial value",
    reduxprops &&
      reduxprops.geod &&
      reduxprops.geod.title &&
      reduxprops.geod.title &&
      reduxprops.geod.title !== undefined
  );
  if (
    reduxprops &&
    reduxprops.geod &&
    reduxprops.geod.title &&
    reduxprops.geod.title
  ) {
    return true;
  } else {
    return false;
  }
};

const UnauthenticatedRoute = ({
  component: Component,
  reduxprops,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated(reduxprops) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const AuthenticatedRoute = ({ component: Component, reduxprops, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated(reduxprops) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading()}>
          <Switch>
            <UnauthenticatedRoute
              exact
              path="/login"
              reduxprops={this.props}
              name="Login Page"
              component={Login}
            />
            <Route exact path="/" name="Home Page" component={Home} />

            <Route
              exact
              path="/contact-us"
              name="Contact us"
              component={ContactUs}
            />
             <Route
              exact
              path="/faqs"
              name="FAQs"
              component={FAQsPage}
            />
            <Route
              exact
              path="/about-us"
              name="About us"
              component={AboutUs}
            />
            <Route
              exact
              path="/terms"
              name="Terms & Conditions"
              component={TermsAndConditions}
            />
            <Route
              exact
              path="/privacy"
              name="Privacy Policy"
              component={PrivacyPolicy}
            />
            <Route
              exact
              path="/forgotpassword"
              name="Forgot Password Page"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/forgot-otp"
              name="ForgotOtp"
              component={ForgotOtp}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <AuthenticatedRoute
              path="/"
              name="Home"
              reduxprops={this.props}
              component={DefaultLayout}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  geod: state.geod,
});

const mapDispatchToProps = {
  activateGeod,
  closeGeod,
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
