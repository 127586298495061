import "./Promotor.css";

import React, { Component } from "react";
import moment from "moment";

import ReactPaginate from "react-paginate";
import { getPromotor } from "../../Service/getPromotor";

export default class ApfBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      loader: false,
      token: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;
    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  async getData() {
    let data = "APF";
    let result = await getPromotor(data);
    console.log("payloadresult", result);
    if (
      result &&
      result.data &&
      result.data.payload &&
      result.data.payload.APF &&
      result.data.payload.APF.length !== 0
    ) {
      const tdata = result.data.payload.APF;
      const slice = tdata.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice,
        loader: false,
      });
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loader: true });
      this.getData();
      this.setState({ token: localStorage.getItem("token"), loader: false });
    } catch (error) {
      console.log(error);
      this.setState({ loader: false });
    }
  }

  render() {
    const { tableData, loader } = this.state;
    return (
      <>
        {loader ? (
          <div className="loader"></div>
        ) : (
          <>
            <div className="border-0 card-header"></div>
            <div className="table-responsive">
              <table className="align-items-center table-flush table">
                <thead className="thead-light">
                  <tr>
                    <th>Sr No</th>
                    <th>Name of Entity</th>
                    <th>Promotor Name</th>
                    <th>Incorporate Year</th>
                    <th>Email</th>
                    <th>Mobile No</th>
                    <th>Loaction</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((obj, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td className="text-capitalize">{obj.promotor_entity}</td>
                      <td className="text-capitalize">{obj.promotor_name}</td>
                      <td>{obj.incorporate_year}</td>
                      <td>{obj.email}</td>
                      <td>{obj.mobile}</td>
                      <td>{obj.loaction}</td>
                      <td>{moment(obj.created_at).format("yyyy-MM-DD")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="py-4 card-footer">
              <ReactPaginate
                previousLabel={<i className="fa fa-angle-left"></i>}
                nextLabel={<i className="fa fa-angle-right"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </>
        )}
      </>
    );
  }
}
