import "./AddBankPartner.css";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import Axios from "../../utils/axiosInstance";
import swal from "sweetalert";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const SignupSchema = Yup.object().shape({
  bankname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  contactpersonname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  contactpersonmobile: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  contactpersonemail: Yup.string().email("Invalid email").required("Required"),
  bankaddress: Yup.string().required("Required"),
});
export default class AddBankPartner extends Component {
  render() {
    return (
      <div className="userAdd__wrapper">
        <Formik
          initialValues={{
            bankname: "",
            contactpersonname: "",
            contactpersonmobile: "",
            contactpersonemail: "",
            bankaddress: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            const data = {
              bank_id: "",
              bank_name: values.bankname,
              contact_person_name: values.contactpersonname,
              contact_person_mobile: values.contactpersonmobile,
              contact_person_email: values.contactpersonemail,
              bank_address: values.bankaddress,
            };
            console.log("data", data);
            let public_id = localStorage.getItem("public_id");
            let token = localStorage.getItem("token");
            return await Axios.post(
              `admin/dashboard/${public_id}/add/partner_bank`,
              data,
              {
                headers: { "x-auth-token": `${token}` },
              }
            )
              .then((result) => {
                swal("Great", "Added successfully", "success");
                window.location.reload();
                return Promise.resolve();
              })
              .catch((result) => {
                swal(
                  "Failed",
                  "Something went wrong please check your internet",
                  "error"
                );
              });
          }}
        >
          {({ errors, touched }) => (
            <div className="mt-7 container-fluid">
              <div className="row">
                <div className="order-xl-1 col-xl-12">
                  <div className="shadow card">
                    <div className="card-body">
                      <Form>
                        <h4 className="heading-small font-weight-bold mb-4">
                          Add Bank Partner
                        </h4>
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Bank Name
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="bankname"
                                  placeholder="Bank Name"
                                />
                                {errors.bankname && touched.bankname ? (
                                  <div className="error__msg">
                                    {errors.bankname}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Contact Person Name
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="contactpersonname"
                                  placeholder="Contact Person Name"
                                />
                                {errors.contactpersonname &&
                                touched.contactpersonname ? (
                                  <div className="error__msg">
                                    {errors.contactpersonname}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Contact Person Mobile
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="contactpersonmobile"
                                  placeholder="Contact Person Mobile"
                                  maxLength="10"
                                />
                                {errors.contactpersonmobile &&
                                touched.contactpersonmobile ? (
                                  <div className="error__msg">
                                    {errors.contactpersonmobile}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Contact Person Email
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="contactpersonemail"
                                  placeholder="Contact Person Email"
                                />
                                {errors.contactpersonemail &&
                                touched.contactpersonemail ? (
                                  <div className="error__msg">
                                    {errors.contactpersonemail}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Bank Address
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="bankaddress"
                                  placeholder="Bank Address"
                                />
                                {errors.bankaddress && touched.bankaddress ? (
                                  <div className="error__msg">
                                    {errors.bankaddress}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="text-center">
                          <button type="submit" className="btn btn-primary">
                            SUBMIT
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}
