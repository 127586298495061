import "./ApplicationRemark.css";

import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import Axios from "../../utils/axiosInstance";
import swal from "sweetalert";

const SignupSchema = Yup.object().shape({
  remark: Yup.string().required("Required"),
});

export default class ApplicationRemark extends Component {
  state = {
    public_id: "",
  };

  componentDidMount = async () => {
    try {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.public_id
      ) {
        const propId =
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.public_id;
        this.setState({ public_id: propId }, () => {
          console.log("public_id", this.state.public_id);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="contactus__wrapper">
        <Formik
          initialValues={{
            remark: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            const data = {
              remark: values.remark,
            };
            console.log("Remark Data", data);
            let token = localStorage.getItem("token");
            return await Axios.put(
              `/admin/dashboard/add_remarks/${this.state.public_id}`,
              data,
              {
                headers: { "x-auth-token": token },
              }
            )
              .then((result) => {
                console.log("Remark", result);
                swal("Great", "Remark send successfully", "success");
                this.props.history.push({
                  pathname: `/view_applications`,
                });
                return Promise.resolve();
              })
              .catch((result) => {
                swal("Failed", "Somthing went wrong", "error");
              });
          }}
        >
          {({ errors, touched }) => (
            <div className="mt-7 container-fluid">
              <div className="row">
                <div className="order-xl-1 col-xl-12">
                  <div className="shadow card">
                    <div className="card-body">
                      <Form>
                        <h4 className="heading-small font-weight-bold mb-4">
                          Add Remark to Applicantion
                        </h4>
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-lg-4 update_password">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Remark
                                </label>
                                <span className="mustRequired">*</span>
                                <Field
                                  className="form-control-alternative form-control"
                                  name="remark"
                                  placeholder="Remark"
                                />
                                {errors.remark && touched.remark ? (
                                  <div className="error__msg">
                                    {errors.remark}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-new"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}
