export default {
  items: [
    {
      name: "Dashborad",
      url: "/dashboard",
      icon: "icon-screen-desktop",
    },
    {
      name: "Applications",
      url: "/view_applications",
      icon: "icon-notebook",
    },
    {
      name: "Query",
      url: "/query",
      icon: "icon-bubbles",
    },
    {
      name: "Contact Us",
      url: "/contact_us",
      icon: "icon-phone",
    },
    {
      name: "Promotors",
      url: "/promotor",
      icon: "icon-organization",
    },
  ],
};
