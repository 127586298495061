import React, { useState, useEffect } from "react";
import { Button, Card, Container, Col, Row, Nav, Form } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";

import BalanceTransfer from "./BalanceTransfer";
import "./HomeLoan.css";

function HomeLoanCalculator() {
  const [selectedOption, setSelectedOption] = useState("1");
  const [grossIncome, setGrossIncome] = useState(0);
  const [otherIncome, setOtherIncome] = useState(0);
  const [creditCardOutstanding, setCreditCardOutstanding] = useState(0);
  const [existingEMI, setExistingEMI] = useState(0);
  const [isIncomeValid, setIsIncomeValid] = useState(true);

  const [coApplicantGrossIncome, setCoApplicantGrossIncome] = useState(0);
  const [coApplicantExistingEMI, setCoApplicantExistingEMI] = useState(0);
  const [tenure, setTenure] = useState(20);

  const [loanAmount, setLoanAmount] = useState(0);
  const [monthlyEMI, setMonthlyEMI] = useState(0);

  const [lifetimeSavings, setLifetimeSavings] = useState(0);
  const [existingMonthlyEMI, setExistingMonthlyEMI] = useState(0);
  const [proposedMonthlyEMI, setProposedMonthlyEMI] = useState(0);
  const [savingMonthlyEMI, setSavingInMonthlyEMI] = useState(0);

  const handleDataChange = (
    savings,
    existingEMI,
    proposedEMI,
    totalSavings
  ) => {
    setLifetimeSavings(savings);
    setExistingMonthlyEMI(existingEMI);
    setProposedMonthlyEMI(proposedEMI);
    setSavingInMonthlyEMI(totalSavings);
  };

  const calculateLoan = () => {
    // Calculating loan amount
    // let tenureInMonths = tenure * 12;
    // const expenseToIncomeRatio = 0.5;
    const fixedObligations =0.35;
    const availableIncome = (Number(grossIncome) -  Number(grossIncome)*fixedObligations);
    let incomeAvailableForProposedLoan =
      (availableIncome -
        // Number(otherIncome) -
        
        Number(existingEMI)) 
      // expenseToIncomeRatio *
      // tenureInMonths;
    if (selectedOption === "2") {
     const availableIncomeCoapp =(Number(coApplicantGrossIncome) -Number(coApplicantGrossIncome)*fixedObligations);
     incomeAvailableForProposedLoan +=
        (availableIncomeCoapp - Number(coApplicantExistingEMI)) 
        // expenseToIncomeRatio *
        // tenureInMonths;
    }
    

    // Calculating monthly EMI
    let principal = incomeAvailableForProposedLoan;
    let interest = interestRate / 1200; // monthly interest rate
    let term = tenure * 12; // total number of months
    function calculateX(interest, term) {
      return( Math.pow(1 + interest, term) -1);
    }
    
    function calculateY(interest, term) {
      return interest * Math.pow(1 + interest, term);
    }
    

    const x = calculateX(interest, term);
    const y = calculateY(interest, term);
//console.log (interest,term,y,x,principal);
    let eligibleLoanAmount = (principal*x)/y;
    setLoanAmount(eligibleLoanAmount.toFixed(2));
    let calculatedMonthlyEMI =(eligibleLoanAmount/term)
      
     // setMonthlyEMI(calculatedMonthlyEMI.toFixed(2));
     setMonthlyEMI(incomeAvailableForProposedLoan);
  };
  //Handling gross Income input
  const handleGrossIncomeChange = (e) => {
    const value = e.target.value;
    setGrossIncome(value);

    if ((value !== 0 && parseInt(value) < 10000) || value.trim() === "") {
      setIsIncomeValid(false);
    } else {
      setIsIncomeValid(true);
    }
  };

  //Handling + ,- Buttons
  let initialInterestRate = (8.6).toFixed(2);
  const [interestRate, setInterestRate] = useState(initialInterestRate);
 // console.log("type "+typeof(interestRate));
  const handleDecrease = () => {
   // console.log(typeof(interestRate));
    if (+interestRate > 6.5) {
      setInterestRate((+interestRate - 0.1).toFixed(2));
    }
  };

  const handleIncrease = () => {
    
    if (+interestRate < 14.9) {
      setInterestRate((+interestRate + 0.1).toFixed(2)); 
    }
  };

  const handleDecreaseTenure = () => {
   // console.log(typeof(+tenure));
    if (+tenure > 1) {
      setTenure(+tenure - 1);
    }
  };
  const handleIncreaseTenure = () => {
   // console.log(typeof(+tenure));
    if (+tenure < 30) {
      setTenure(+tenure + 1);
    }
  };

  useEffect(() => {
    calculateLoan();
  }, [
    grossIncome,
    otherIncome,
    creditCardOutstanding,
    existingEMI,

    coApplicantGrossIncome,
    coApplicantExistingEMI,
    tenure,
    interestRate,
  ]);

  //Tab changes
  const [activeTab, setActiveTab] = useState("New HomeBuyer");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  //option change Justme to Me+Co-Applicant
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Container fluid id="home-calculator">
        <Row>
          <Col xs={12} md={9} className="cal-pl-50">
            <div>
              <h2 className="my-5 mob-mt-1rem home-calculator ">
             <u className="underLine"> Home Loan Calculator </u>
                <br />
                {/* <hr className="hr-Home " /> */}
              </h2>
            </div>
            <Tab.Container
              activeKey={activeTab}
              onSelect={handleTabChange}
              id="uncontrolled-tab-example"
              className="mb-3 "
            >
              <Row>
                <Row sm={12}>
                  <Col>
                  {/* <Nav variant="pills" className="flex-row tab-group mb-5 justify-content-center" style={{backgroundColor:"#fff"}}>
                      <Nav.Item style={{border:"1px solid #c0c0c0",borderRadius:"5px"}}>
                        <Nav.Link
                          eventKey="New HomeBuyer"
                          className="tab px-5 custom-calc-btn"
                          title="New HomeBuyer"
                        >
                          <b>Loan Calculator</b>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item style={{border:"1px solid #c0c0c0",borderRadius:"5px"}}>
                        <Nav.Link
                          eventKey="Balance Transfer"
                          title="Balance Transfer"
                          className="tab px-5 custom-calc-btn"
                        >
                          <b> Loan Transfer</b>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav> */} 
                  </Col>
                </Row>
                <Row sm={12}>
                  <Tab.Content className="custom-tab-content ">
                    <Tab.Pane eventKey="New HomeBuyer">
                      <Row>
                        <Col>
                          <Form>
                            <Row>
                              <Col xs={6} md={3}>
                                <Form.Check
                                className="font-weight-bold"
                                  type="radio"
                                  label="Just Me"
                                  name="options"
                                  id="me"
                                  value="1"
                                  checked={selectedOption === "1"}
                                  onChange={handleOptionChange}
                                />
                              </Col>
                              <Col>
                                <Form.Check
                                className="font-weight-bold"
                                  type="radio"
                                  label="Me + Co-Applicant"
                                  id="co-applicant"
                                  name="options"
                                  value="2"
                                  checked={selectedOption === "2"}
                                  onChange={handleOptionChange}
                                />
                              </Col>
                            </Row>
                          </Form>

                          {selectedOption === "1" && (
                            <>
                              <Row className="mt-1">
                                {/* Just me Column 1 */}
                                <Col className="border-right " >
                                  <Row
                                    className="mt-3"
                                    style={{ color: "#002a3a" }}
                                  >
                                    <Col md={6}>
                                      <label className=" font-sarabun font-weight-500">
                                        Gross Income
                                        <br />
                                        (monthly)
                                      </label>

                                      <div className="input-group w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={grossIncome}
                                          onChange={handleGrossIncomeChange}
                                          className={`input form-control ${
                                            isIncomeValid ? 0 : "is-invalid"
                                          }`}
                                        />
                                        {isIncomeValid &&
                                          grossIncome === "" && (
                                            <div className="invalid-feedback">
                                              Gross Income is required.
                                            </div>
                                          )}
                                        {!isIncomeValid && (
                                          <div className="invalid-feedback">
                                            Monthly income cannot be less than
                                            10,000.
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                    {/* <Col>
                                      <label className=" font-sarabun font-weight-500">
                                        Other Income
                                        <br />
                                        (monthly)
                                      </label>

                                      <div className="input-group  w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={otherIncome}
                                          onChange={(e) =>
                                            setOtherIncome(e.target.value)
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col> */}
                                  </Row>
                                  <Row
                                    className="mt-3"
                                    style={{ color: "#002a3a" }}
                                  >
                                   {/* <Col>
                                      <label className=" font-sarabun font-weight-500">
                                        Credit Card Outstanding
                                      </label>

                                      <div className="input-group w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={creditCardOutstanding}
                                          onChange={(e) =>
                                            setCreditCardOutstanding(
                                              e.target.value
                                            )
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col>*/}
                                    <Col md={6}>
                                      <label className=" font-sarabun font-weight-500">
                                        Existing EMI
                                      </label>

                                      <div className="input-group  w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={existingEMI}
                                          onChange={(e) =>
                                            setExistingEMI(e.target.value)
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="pt-5">
                                  <Row className="my-4"></Row>
                                  <Row
                                    style={{ color: "#002a3a" }}
                                    className="mt-5"
                                  >
                                    <Col>
                                      <label
                                        for="principaloutstanding"
                                        className="mb-3 col-12 font-sarabun font-weight-500"
                                      >
                                        Tenure
                                        <br />
                                        (years)
                                      </label>

                                      <label for="tenure-year" className="col-12">
                                        <div className="input-group mb-3 w-210">
                                          <button
                                            onClick={handleDecreaseTenure}
                                            className="input-group-text fw-semi-bold"
                                          >
                                            -
                                          </button>
                                          <input
                                            
                                            inputMode="numeric"
                                            name="tenure"
                                            formcontrolname="tenure"
                                            maxlength="2"
                                            min={1}
                                            max={30}
                                            numberonly
                                            className="form-control text-center "
                                            value={tenure}
                                            onChange={(e) =>
                                              setTenure(e.target.value)
                                            }
                                          />
                                          <button
                                            className="input-group-text fw-semi-bold"
                                            onClick={handleIncreaseTenure}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </label>
                                    </Col>
                                    <Col>
                                      <label
                                        for="principaloutstanding"
                                        className="mb-3 col-12 font-sarabun font-weight-500"
                                      >
                                        Interest Rate
                                        <br />
                                        (% p.a.)
                                      </label>
                                      <label for="intrst-rate" className="col-12">
                                        <div className="input-group mb-3 w210">
                                          <button
                                            onClick={handleDecrease}
                                            className="input-group-text fw-semi-bold"
                                          >
                                            -
                                          </button>
                                          <input
                                            
                                            inputMode="numeric"
                                            name="interest_rate"
                                            formcontrolname="interest_rate"
                                            autocomplete="off"
                                            min="6.5"
                                            max="15"
                                            placeholder
                                            numberonly
                                            className="form-control text-center "
                                            value={interestRate}
                                            onChange={(e) =>
                                              setInterestRate(e.target.value)
                                            }
                                          />
                                          <button
                                            className="input-group-text fw-semi-bold"
                                            onClick={handleIncrease}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="mt-5 mb-3 disclaimer">
                                Note: Any family member could be a co-applicant.
                              </Row>
                            </>
                          )}
                          {selectedOption === "2" && (
                            <>
                              <Row
                                style={{ color: "#002a3a" }}
                                className="mt-1"
                              >
                                {/* Just me Column 1 */}
                                <Col className="border-right">
                                  <Row className="mt-3">
                                    <Col md={6}>
                                      <label className=" font-sarabun font-weight-500">
                                        Gross Income
                                        <br />
                                        (monthly)
                                      </label>

                                      <div className="input-group w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={grossIncome}
                                          onChange={handleGrossIncomeChange}
                                          className={`input form-control ${
                                            isIncomeValid ? 0 : "is-invalid"
                                          }`}
                                        />
                                        {!isIncomeValid && (
                                          <div className="invalid-feedback">
                                            Monthly income cannot be less than
                                            10,000.
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                    {/* <Col>
                                      <label className=" font-sarabun font-weight-500">
                                        Other Income
                                        <br />
                                        (monthly)
                                      </label>

                                      <div className="input-group  w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={otherIncome}
                                          onChange={(e) =>
                                            setOtherIncome(e.target.value)
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col> */}
                                  </Row>
                                  <Row className="mt-3">
                                  {/*  <Col>
                                      <label className=" font-sarabun font-weight-500">
                                        Credit Card Outstanding
                                      </label>

                                      <div className="input-group w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={creditCardOutstanding}
                                          onChange={(e) =>
                                            setCreditCardOutstanding(
                                              e.target.value
                                            )
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                        </Col>*/}
                                    <Col md={6}>
                                      <label className=" font-sarabun font-weight-500">
                                        Existing EMI
                                      </label>

                                      <div className="input-group  w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={existingEMI}
                                          onChange={(e) =>
                                            setExistingEMI(e.target.value)
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                  <Col xs={12}>
                                  <h4
                                        className="mt-4"
                                        style={{ color: "#002a3a" }}
                                      >
                                        Co-Applicant
                                      </h4>
                                  </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col md={6}>
                                      <label
                                        aria-label="Salary monthly net"
                                        className=" font-sarabun font-weight-500"
                                      >
                                        Gross Income
                                        <br />
                                        (monthly)
                                      </label>

                                      <div className="input-group w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          placeholder=""
                                          value={coApplicantGrossIncome}
                                          onChange={(e) =>
                                            setCoApplicantGrossIncome(
                                              e.target.value
                                            )
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <label
                                        aria-label="Salary monthly net"
                                        className=" font-sarabun font-weight-500"
                                      >
                                        Existing EMI
                                        <br />
                                        <span className="invisible">(monthly)</span>
                                      </label>

                                      <div className="input-group  w100">
                                        <span className="input-group-text font-default">
                                          ₹
                                        </span>
                                        <input
                                          inputMode="numeric"
                                          value={coApplicantExistingEMI}
                                          onChange={(e) =>
                                            setCoApplicantExistingEMI(
                                              e.target.value
                                            )
                                          }
                                          className="input form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="pt-5">
                                  <Row className="my-5 pt-5"></Row>
                                  <Row className="mt-5 pt-4">
                                    <Col className="mt-5">
                                      <label
                                        for="principaloutstanding"
                                        className="mb-3 col-12 font-sarabun font-weight-500"
                                      >
                                        Tenure
                                        <br />
                                        (years)
                                      </label>

                                      <label
                                        for="tenure-year"
                                        className="col-12"
                                      >
                                        <div className="input-group mb-3 w-210">
                                          <button
                                            onClick={handleDecreaseTenure}
                                            className="input-group-text fw-semi-bold"
                                          >
                                            -
                                          </button>
                                          <input
                                            
                                            inputMode="numeric"
                                            name="tenure"
                                            formcontrolname="tenure"
                                            maxlength="2"
                                            min={1}
                                            max={30}
                                            numberonly
                                            className="form-control text-center "
                                            value={tenure}
                                            onChange={(e) =>
                                              setTenure(e.target.value)
                                            }
                                          />
                                          <button
                                            className="input-group-text fw-semi-bold"
                                            onClick={handleIncreaseTenure}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </label>
                                    </Col>
                                    <Col className="mt-5">
                                      <label
                                        for="principaloutstanding"
                                        className="mb-3 col-12 font-sarabun font-weight-500"
                                      >
                                        Interest Rate
                                        <br />
                                        (% p.a.)
                                      </label>
                                      <label
                                        for="intrst-rate"
                                        className="col-12"
                                      >
                                        <div className="input-group mb-3 w210">
                                          <button
                                            onClick={handleDecrease}
                                            className="input-group-text fw-semi-bold"
                                          >
                                            -
                                          </button>
                                          <input
                                            
                                            name="interest_rate"
                                            formcontrolname="interest_rate"
                                            min="6.5"
                                            max="15"
                                            autocomplete="off"
                                            placeholder
                                            numberonly
                                            twodigitdecimal
                                            className="form-control text-center "
                                            step="0.1"
                                            value={interestRate}
                                            onChange={(e) =>
                                              setInterestRate(e.target.value)
                                            }
                                          />
                                          <button
                                            className="input-group-text fw-semi-bold"
                                            onClick={handleIncrease}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="mt-5 mb-3 disclaimer">
                                Note: Any family member could be a co-applicant.
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Balance Transfer">
                      <BalanceTransfer onDataUpdate={handleDataChange} />
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Row>
            </Tab.Container>
          </Col>
          {/* Right side of calculator New Homebuyer */}
          <Col
            xs={12}
            md={3}
            style={{ backgroundColor: "#072c5e", paddingRight: "50px" }}
          >
            <Container fluid className="p-0 mob-max-width-90">
              <Row>
                <Col xs={12} className="pt-5 calc-lftpadding">
                  {activeTab === "New HomeBuyer" && (
                    <Container>
                      <h4 className="heading mt-5 mob-mt-1rem font-wt-medium rightSec-leble">
                        Get Home Loan upto
                      </h4>
                      <p className="total-saving-txt mb-4 right-side-cal text-white font-sarabun">
                        <span className="right-side-cal">
                          <span className="font-default right-side-cal">₹</span>{" "}
                          {loanAmount}
                        </span>
                      </p>
                      <hr className="hr-loan" />
                      <Row className="my-4">
                        <Col xs={12}>
                          <p className="heading mt-4 font-wt-medium rightSec-leble">
                            With Monthly EMI of
                          </p>
                          <p className=" mb-4 right-side-cal font-sarabun">
                            <span className="right-side-cal">
                              <span className="font-default right-side-cal">
                                ₹{" "}
                              </span>
                              {monthlyEMI}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <hr className="hr-loan" />
                      <Row className="my-4">
                        <Col xs={6}>
                          <p className="heading mt-4 rightSec-leble">
                            Tenure
                            <br />
                            (years)
                          </p>
                          <p className=" font-sarabun right-side-cal">
                            {tenure}
                          </p>
                        </Col>
                        <Col xs={6}>
                          <p className="heading mt-4 rightSec-leble">
                            Interest Rate
                            <br />
                            (% p.a.)
                          </p>
                          <p className=" font-sarabun right-side-cal">
                            {interestRate}
                          </p>
                        </Col>
                      </Row>
                   {/*<Button
                        href="/"
                        target=""
                        aria-label="Check Eligibility"
                        className="btn bg-teal ml-2 btn-padding text-white"
                      >
                        Download Eligibility Report
                      </Button> */}
                    </Container>
                  )}
                  {activeTab === "Balance Transfer" && (
                    <Container>
                      <h6 className="heading mt-5 mob-mt-1rem font-wt-medium rightSec-leble">
                        Lifetime savings in cashflow
                      </h6>
                      <p className="total-saving-txt mb-4 right-side-cal text-white font-sarabun">
                        <span className="right-side-cal">
                          <span className="font-default right-side-cal">₹</span>
                          {lifetimeSavings}
                        </span>
                      </p>
                      <hr className="hr-loan" />
                      <Container>
                        <Row>
                          <p className="heading mt-4 font-wt-medium rightSec-leble">
                            Existing monthly EMI
                          </p>
                          <p className=" mb-4 right-side-cal font-sarabun">
                            <span className="right-side-cal">
                              <span className="font-default right-side-cal">
                                ₹{" "}
                              </span>
                              {existingMonthlyEMI}
                            </span>
                          </p>
                        </Row>
                        <Row>
                          <p className="heading mt-4 font-wt-medium rightSec-leble">
                            Proposed monthly EMI
                          </p>
                          <p className=" mb-4 right-side-cal font-sarabun">
                            <span className="right-side-cal">
                              <span className="font-default right-side-cal">
                                ₹{" "}
                              </span>
                              {proposedMonthlyEMI}
                            </span>
                          </p>
                        </Row>
                      </Container>

                      <hr className="hr-loan " />

                      <Row className="my-4">
                        <Col xs={12}>
                          <p className="heading mt-4 font-wt-medium rightSec-leble">
                            Saving in monthly EMI
                          </p>
                          <p className=" mb-4 right-side-cal font-sarabun">
                            <span className="right-side-cal">
                              <span className="font-default right-side-cal">
                                ₹{" "}
                              </span>
                              {savingMonthlyEMI}
                            </span>
                          </p>
                        </Col>
                      </Row>
                      
                    </Container>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default HomeLoanCalculator;
